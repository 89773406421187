import React, { useState, useEffect } from 'react';
import { BsChevronDown, BsDownload, BsArchive, BsCopy } from 'react-icons/bs';
import { RightContainer, Row, Badge } from "../../shared/utils"
import AddUserModalContent from "./AddUserModalContent";
import Modal from "../../shared/Modal";
import Tooltip from '@mui/material/Tooltip'; 
import { getkycAll, deletekyc, frontEndBaseURL, getkycPDF, generateFormToken, getKycFormToCsv } from '../../../API/Api'
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DateTime } from 'luxon';
import FormStatusEnum from '../../../data/FormStatusEnum';
import 'react-toastify/dist/ReactToastify.css';
import { jwtDecode } from 'jwt-decode';


const _KycAmlCustomerListTable = () => {
  const currentLocation = useLocation();
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState(null);
  const [users, setUsers] = useState([]);
  const [displayedUsers, setDisplayedUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortDirection, setSortDirection] = useState('desc');
  const [collapsed, setCollapsed] = useState(false); // State for collapsing the table
  const token = localStorage.getItem('accessToken')
  const decodedToken =  jwtDecode(token)
  const userRole = decodedToken.role 

  useEffect( () => {
    const fetchData = async () => { 
      const accessToken = jwtDecode(localStorage.getItem('accessToken'))
      let companyId = accessToken.companyId;

      const allKycData = await getkycAll(companyId, token)

      const activeKycData = []
      allKycData.data.forEach(item => {
        // check how many days to next major rescreening
        const givenDate = DateTime.fromFormat(item.nextRescreeningDate || "27-06-2099", "dd-MM-yyyy"); // default Date needed for results w/o rescreening result
        const diff = Math.round(givenDate.diff(DateTime.now(), 'days').days);

        let expiryStatus;
        if (diff < 0) {
          expiryStatus = 'expired'
        } else if (diff < 31) {
          expiryStatus = 'expiringSoon'
        }

        if (!item.archived) {
          activeKycData.push({
            id: item._id,
            name: item.name,
            email: item.email,
            type: item.type,
            maxPage: item.maxPage,
            nextRescreeningDate: item.nextRescreeningDate,
            companyId: item.companyId,
            // assignedDate: item.submitedDate,
            // lastActivity: item.updatedDate,
            status: expiryStatus || item.formStatus,
            // expiryStatus: expiryStatus 
          })
        }
      })
      setUsers(activeKycData)
      setDisplayedUsers(activeKycData)
      handleStatusSort('status')
      await new Promise(r => setTimeout(r, 150));
      setLoading(false); 
    }
    fetchData()
      }, // console.error('Error fetching KYC data:', error);
  [loading]);

  const getStatusTag = (status) => {
    if (status == FormStatusEnum.PENDING_CLIENT_DETAILS_1) {
        return <label className="bg-yellow-200 text-black rounded-full px-2 py-1 text-xs font-semibold">Pending Client Details</label> 
    }
    else if (status == FormStatusEnum.PENDING_RISK_ASSESSMENT_2) {
        return <label className="bg-amber-400 text-black rounded-full px-2 py-1 text-xs font-semibold">Pending Risk Assessment</label>
    }
    else if (status == FormStatusEnum.PENDING_ADMIN_REVIEW_3) {
        return <label className="bg-orange-400 text-black rounded-full px-2 py-1 text-xs font-semibold">Pending Manager Review</label> 
    }
    else if (status == 'expiringSoon') {
      return <label className="bg-gray-400 text-white rounded-full px-2 py-1 text-xs font-semibold">Expiring Soon</label> 
    }
    else if (status == 'expired') {
      return <label className="bg-black text-white rounded-full px-2 py-1 text-xs font-semibold">Expired</label> 
    }
    else if (status == FormStatusEnum.ACCEPTED_4) {
        return <label className="bg-green-400 text-black rounded-full px-2 py-1 text-xs font-semibold">Ongoing Monitoring</label>  
    }
    else if (status == FormStatusEnum.REJECTED_4) {
        return <label className="bg-red-400 text-black rounded-full px-2 py-1 text-xs font-semibold">Rejected</label> 
    } 
  }

  const handleKycPDF = async(userId, userName) => {
      const response = await getkycPDF(userId, token);  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;

      const filename = `${userName}_Details_${DateTime.now().setZone('Asia/Singapore').toFormat("dd-MM-yyyy")}.pdf`;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
}
 

const handleKycDownloadCsv = async (kycId, kycName) => {
  try {
    const response = await getKycFormToCsv(kycId, token);
    // Create a Blob from the array buffer
    const blob = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
    
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${decodedToken.companyId} Summary report - ${DateTime.now().toFormat('dd-MM-yyyy')}.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  } catch (err) {
    console.error('Download failed:');
    toast.error('Download failed...')
  }
};



  const copyFormLink = async (userId, maxPage, companyId, user) => {
    if (userRole == 'viewer') { 
      toast.info("Viewer cannot perform that action")
      return 
    }

    const formTokenData = {
      name: user.name,
      userId : userId,
      companyId : companyId,
      // allowedMethods : ['getkyc', 'updatekyc', '/health']
    }

    const formToken = (await generateFormToken(formTokenData, token)).data.accessToken  // what data
    const linkToCopy = frontEndBaseURL + '/form/kyc-aml?id=' + userId + '&page=' + maxPage + '&formToken=' + formToken;
    await navigator.clipboard.writeText(linkToCopy)

    toast.success("External Form Link Copied");
  }

  const convertToSingaporeTime = (dateTime) => {
    if (!dateTime) {return ''}

    const utcTimestamp = new Date(dateTime);
    const singaporeTime = new Date(utcTimestamp.getTime());  
    // const singaporeTime = new Date(utcTimestamp.getTime() + (8 * 60 * 60 * 1000)); // Adding 8 hours (UTC+8)

    // Format the date and time in "26th Feb 2024, 11:41" format
    const options = { 
      year: 'numeric', 
      month: 'short', 
      day: '2-digit', 
      hour: '2-digit', 
      minute: '2-digit', 
      hour12: false 
    };

    const formatter = new Intl.DateTimeFormat('en-SG', options);
    return formatter.format(singaporeTime);
  }


  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
    setDisplayedUsers( users.filter((user) => user.name.toLowerCase().includes(e.target.value.toLowerCase())) )
  };

  const handleArchive = async (userId) => {
    if (userRole == 'viewer') { 
      toast.info("Viewer cannot perform that action")
      return 
    }

    await deletekyc(userId, token)
    setLoading(true) // have to trigger re-fetch from DB cuz need the added objectId
    notifyArchived()
  }

  const handleDateSort = (column) => {
    if (sortBy === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortDirection('desc');
    }
 
    let sortedUsers1 = users.sort((a, b) => {
      const date1 = isValidDate(a.nextRescreeningDate) ? new Date(a.nextRescreeningDate.split('-').reverse().join(',')) : null;
      const date2 = isValidDate(b.nextRescreeningDate) ? new Date(b.nextRescreeningDate.split('-').reverse().join(',')) : null;
      function isValidDate(dateString) {
        return dateString && !isNaN(new Date(dateString.split('-').reverse().join(',')).getTime());
      }
      const sortOrder = sortDirection === 'desc' ? -1 : 1; // Determine sort order based on sortDirection
      // Handle null or invalid dates by sorting them to the end
      if (!date1 && !date2) {
        return 0; // Both dates are invalid or null, no sorting needed
      } else if (!date1) {
        return sortOrder; // a.nextRescreeningDate is invalid/null, sort b before a
      } else if (!date2) {
        return sortOrder * -1; // b.nextRescreeningDate is invalid/null, sort a before b
      }
      // Compare valid dates as before
      if (date1.getTime() < date2.getTime()) {
        return sortOrder;
      } else if (date1.getTime() > date2.getTime()) {
        return sortOrder * -1;
      } else {
        return 0;
      }
    });
    const sortedUsersNoRescreeningDates = sortedUsers1.filter(user => user.nextRescreeningDate);
    const sortedUsersRescreeningDates = sortedUsers1.filter(user => !user.nextRescreeningDate)
    const sortedUsers =  [...sortedUsersNoRescreeningDates, ...sortedUsersRescreeningDates];
  
    setDisplayedUsers(sortedUsers)
  };

  const handleStatusSort = (column) => {
    const statuses = {
      PENDING_CLIENT_DETAILS_1: 'pending_client_details',
      PENDING_RISK_ASSESSMENT_2: 'pending_risk_assessment',
      PENDING_ADMIN_REVIEW_3: 'pending_manager_review',
      REJECTED_4: 'rejected',
      ACCEPTED_5: 'accepted',
      EXPIRE_SOON_6: 'expiringSoon',
      EXPIRED_7: 'expired'
    };
    
    const statusOrder = Object.values(statuses).reduce((order, status, index) => {
      order[status] = index;
      return order;
    }, {});
    
    function sortByStatus(users, ascending = true) {
      return users.slice().sort((a, b) => {
        const orderA = statusOrder[a.status];
        const orderB = statusOrder[b.status];
    
        if (orderA === undefined || orderB === undefined) {
          console.error('Encountered undefined status:', a.status, 'or', b.status);
          return ascending ? -1 : 1;
        }
    
        if (orderA !== orderB) {
          return ascending ? orderA - orderB : orderB - orderA;
        }
    
        // If statuses are the same, use nextRescreening as tiebreaker
        const dateA = new Date(a.nextRescreening);
        const dateB = new Date(b.nextRescreening);
    
        if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
          // console.log('Invalid nextRescreening date:', a.nextRescreening, 'or', b.nextRescreening);
          return 1; // this changed, check if sorting affected
        }

        // sort by updatedAt if its not a 'accepted' status
        if (dateA.getTime() !== dateB.getTime()) {
          return ascending ? dateA - dateB : dateB - dateA;
        }
    
        // If nextRescreening dates are the same, use createdAt as final tiebreaker
        const createdAtA = new Date(a.submitedDate);
        const createdAtB = new Date(b.submitedDate);
    
        if (isNaN(createdAtA.getTime()) || isNaN(createdAtB.getTime())) {
          console.error('Invalid createdAt date:', a.submitedDate, 'or', b.submitedDate);
          return 0;
        }
    
        // Sort by createdAt in descending order (more recent first)
        return createdAtB - createdAtA;
      });
    }

    if (sortBy === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortDirection('desc');
    }
    setDisplayedUsers(sortByStatus(users, sortDirection === 'desc'));
  }



  const handleCollapseToggle = () => {
    setCollapsed(!collapsed);
  };

  const notify = () => toast.success("User(s) successfully added!"); 
  const notifyMissing = () => toast.info("Please fill in all fields");
  const notifyArchived = () => toast.info("User successfully archived");


  return (
    <div >
      <Row> {/*  Top menu Options */}
        <div className="w-full flex justify-between items-center p-4 bg-white shadow">
          <strong>KYC reporting status</strong>
          <input
              type="text"
              placeholder="Search Name"
              value={searchTerm}
              onChange={handleSearchChange}
              className="p-2 border border-gray-300 rounded-lg mr-2 w-1/3"
          />
          <Modal title="Add Customer">
            <AddUserModalContent notify={notify} notifyMissing={notifyMissing} setLoading={setLoading}/>
          </Modal>

          <Link to='/kyc-aml/archived-customer-list' replace={currentLocation.pathname === '/'}>
            <button className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-lg">
              View Archives
            </button>
          </Link>

          <button onClick={handleKycDownloadCsv} className="bg-green-400 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-lg">
              Download All
            </button>

          <button onClick={handleCollapseToggle} className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-2 rounded-full">
            <BsChevronDown/>
            {/* <BsChevronDown className={`${Menu.isOpen && 'rotate-180'}`} /> */}
          </button>
        </div>
      </Row> {/* Display Table Options */}
      {!collapsed && (
          <div>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <div>
            <table className="w-full border-collapse border border-gray-300 shadow table-auto">
              <thead>
                <tr className="bg-gray-300 text-left">
                  <th className="p-2">Name</th>
                  <th className="p-2">Email</th>
                  <th className="p-2 cursor-pointer text-center" onClick={() => handleDateSort('nextRescreeningDate')}> Next Review  <span className='pl-2 pt-1 inline-block'> <BsChevronDown  /> </span> {/* <BsChevronDown className={`${Menu.isOpen && 'rotate-180'}`} /> */} </th>
                  <th className="p-2 cursor-pointer text-center" onClick={() => handleStatusSort('status')}> Status <span className='pl-2 pt-1'> <BsChevronDown className='inline-block'/> </span>  </th>
                  <th className="p-2 text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {displayedUsers.map((user, index) => (
                  <tr key={user.id} className={index % 2 === 0 ? 'bg-green-50 hover:bg-green-100' : 'bg-white hover:bg-green-100'}>
                    <Link to={`/kyc-aml/form?id=${user.id}&page=${user.maxPage}`} replace={currentLocation.pathname === `/kyc-aml/overview-assessment?id=${user.id}&page=${user.maxPage}`}>
                      <Tooltip title={<p style={{ fontSize: "14px" }}> Edit {user.type}, {user.name} </p>} className='border-stone-500' arrow>
                        <td className="p-2 w-3/12">{user.name}</td>
                      </Tooltip> 
                    </Link>
                    <td className="p-2 w-3/12"> {user.email} </td>
                    {/* <td className="p-2 w-1/6">{convertToSingaporeTime(user.assignedDate)}</td> */}
                    <td className="p-2 w-2/12 text-center">{user.nextRescreeningDate || '-'}</td>
                    <td className="p-2 w-2/12 text-xs text-center">{getStatusTag(user.status)}</td>
                    <td className="p-2 w-1/12">
                      <div className="flex">
                        {/* <i onClick={() => copyFormLink(user.id)} className='p-2.5 mx-1 rounded-full  border border-stone-500 cursor-pointer hover:bg-gray-400 transition duration-500'> <Tooltip title={"Copy Form Link"} arrow> <BsCopy /> </Tooltip></i>   */}
                        <Tooltip title={"Copy Form Link"} onClick={() => copyFormLink(user.id, user.maxPage, user.companyId, user)} className='p-2.5 mx-1 rounded-full border border-stone-500 cursor-pointer hover:bg-gray-400  transition duration-500' arrow>  <BsCopy/>  </Tooltip>
                        <Tooltip title={"Download"}  onClick={() => handleKycPDF(user.id, user.name)} className='p-2.5 mx-1 rounded-full cursor-pointer hover:bg-gray-400 border border-stone-500 transition duration-500' arrow> <BsDownload /> </Tooltip> 
                        {/* <Tooltip title={"Download (WIP)"}  onClick={()=> handleKycDownloadCsv(user.id, user.name)} className='p-2.5 mx-1 rounded-full cursor-pointer hover:bg-gray-400 border border-stone-500 transition duration-500' arrow> <BsDownload /> </Tooltip>  */}
                        <Tooltip title={"Archive"} onClick={() => handleArchive(user.id) } className='p-2.5 mx-1 rounded-full cursor-pointer hover:bg-gray-400 border border-stone-500 transition duration-500' arrow> <BsArchive /> </Tooltip>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          )}
        </div>
      )}
    </div>
  );
};


export default _KycAmlCustomerListTable;
   