import React, {useRef} from 'react'


const DeclarationCard = ({handleInputChange}) => {
  return (
    <div>
        <p> <strong> Declaration: </strong> I have read the Fit and Proper Criteria abovementioned and in submitting this form; I declare that all information given in this application and in the attached annexes (if any) is true and correct and that I have not willfully suppressed any material fact. I understand that any false declaration or misrepresentation may result in the rejection of the application and/or claims and/or any grants disbursed will be recovered and my organization may be disqualified from all future applicationsbe subject to disciplinary actions. </p>
        <div className="col-span-1 md:col-span-12 grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
            {/* <div className="col-span-1 md:col-span-2">
            <strong> Annex A: </strong>
            </div> */}
            <div>
                <p className="font-medium text-gray-700 mb-1"> Employee Code: </p>
                <input type="text" name='employeeCode' placeholder="Enter Name of Regulator/Authority" onChange={handleInputChange} className="w-full p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
            </div>
            <div>
                <p className="font-medium text-gray-700 mb-1"> Name: </p>
                <input type="text" name='declarationName' placeholder="Enter Nature of incident for affirmative response (“Incident”)" onChange={handleInputChange} className="w-full p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
            </div>
            <div>
                <p className="block font-medium text-gray-700 mb-1"> Designation: </p>
                <input type="text"name='designation' placeholder="Enter Details of Incident" onChange={handleInputChange} className="w-full p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
            </div>
            <div>
                <p className="block font-medium text-gray-700 mb-1"> Date: </p>
                <input type="date" name='declarationDate' onChange={handleInputChange} className="w-full p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
            </div>

            <div className="col-span-1 md:col-span-2 w-7/12 mb-6">
                <strong> Signature: </strong>
                <input type="text" name='declarationSignature'  className="w-full p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
                <div className='border p-12'>  </div>
                <button className='border bg-gray-400 p-2'> Clear </button>
            </div>
        </div>

        <div className='flex mb-5 justify-end'>
            <button className="bg-green-500 hover:bg-green-600 text-white font-semibold py-1.5 px-4 rounded-sm"> Submit </button>
            <button className="bg-yellow-500 hover:bg-yellow-600 text-white font-semibold py-1.5 px-4 rounded-sm mx-3"> Review </button>
            <button className="bg-green-500 hover:bg-green-600  mx-2 text-white font-semibold py-1.5 px-4 rounded-sm"> Approve </button>
        </div>
        <p> Reviewed by admin at: {"24th, Jun, 2024 04:23"} </p>
        <p> Approved by admin at: {"24th, Jun, 2024 04:23"} </p>
    </div>
  )
}

export default DeclarationCard