import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const RiskRatingCounterChart = () => {
  // Declare mock data
  const chartData = {
    labels: [
      '01-09-2024', 
      '02-09-2024', 
      '03-09-2024', 
      '04-09-2024', 
      '05-09-2024', 
      '06-09-2024', 
      '07-09-2024'
    ],
    datasets: [
      {
        label: 'Forms Completed',
        data: [5, 12, 8, 15, 7, 10, 18], // Mock data for the Y-axis
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderWidth: 2,
        fill: true,
      }
    ]
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
      // title: {
      //   display: true,
      //   text: 'Number of Forms Completed Over the Last 7 Days',
      // },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Forms Completed',
        },
        beginAtZero: true,
      },
    },
  };

  return (
    <div>
      <Line data={chartData} options={options} />
    </div>
  );
};

export default RiskRatingCounterChart;
