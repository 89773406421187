import React, { useState, useEffect } from 'react';
import { uploadFile, updatekyc ,updateKycRescreeningDate, createNotification, createKycComment, getkycRiskAssessment, createKycNestedSanctionComment, sendEmailNotification, getkyc, getkycAll, updatekycStatus, sanctionsHits, adverseNewsHits, createkycRiskAssessment, updatekycRiskAssessment, getAllUsers, frontEndBaseURL} from "../../../API/Api" 
import { Card } from '../../shared/utils';
import QuestionCard from './QuestionCard'; 
// import FormStatusEnum from '../../../data/FormStatusEnum'
import { DateTime } from "luxon"
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import {ListB, ListC, ListD, highRiskJurisdictions, transparencyInternational } from '../../../data/RiskCountries'
import SanctionsioScreeningCard from './SanctionsioScreeningCard';
import SubmissionCard from './SubmissionCard';
import { jwtDecode } from "jwt-decode";


// const IndividualRiskAssessment = ({userId}) => {
const _RiskAssessment = ({userId, userType, readOnly, currentUrl, token}) => {
  const decodedToken =  jwtDecode(token)

  const [allFormData, setAllFormData] = useState([])
  const [formData, setFormData] = useState({
    kycaml_client_id: userId, created_at: '', selectedDate: '', submittedBy: '', managerReviewEmail: [],
    sourceOfWealthDocumentURL: '', otherDocumentsURL: '', managementSupportDocumentURL: '',
    riskLevel: '', screenedDate: '', nextRescreeningDate: '', status: 'new', //   // status: 'yet to be decided by manager, aka pending_admin_review',

    singaporeGovernmentEntity: '', listedOnStockExchange: '', singaporeFI: '', nonSingaporeFI: '', 
    politicallyExposedPerson: '', countryCountermeasureFATF: '', countryInadequateAML_CFT: '', 
    higherRiskSector: '', highRiskJurisdictionFATF: '', credibleBodyIdentifiedCountry: '',
    
    investmentVehicle : { response: '', justification: '', newComment:'', comments:[] }, // comment object [ {name: '', content: '', time: '', id: ''} ]
    customerPensionSuperannuation: { response: '', justification: '', newComment:'', comments:[]},  
    unverifiedBusinessActivity: { response: '', justification: '', newComment:'', comments:[] },  
    unverifiedCorporateStructure: { response: '', justification: '', newComment:'', comments:[] },  
    unverifiedFinancialActivity: { response: '', justification: '', newComment:'', comments:[] },  
    unusualOwnershipStructure: { response: '', justification: '', newComment:'', comments:[] }, 
    unusualBusinessCircumstances: { response: '', justification: '', newComment:'', comments:[] }, 
    nomineeShareholdersBearer: { response: '', justification: '', newComment:'', comments:[] }, 
    anonymousTranscations: { response: '', justification: '', newComment:'', comments:[] }, 
    unknownPaymentSources: { response: '', justification: '', newComment:'', comments:[] }, 

    pepHits:[], 
    //   nameTitle: '', positionStart: 'NA', positionEnd: 'NA',  si_identifier: '',  
    //   answer: '', justification: '', comments:[], newComment // just reference it, save as empty string if needed.
    crimesHits:[],
    // nameDataSource: '', remarks: '',  // name: '', dataSource: '', remarks: '', 
    // answer: '', justification: '', comments:[] // comment object [ {name: '', content: '', time: '', id: ''} ]
    sanctionsHits:[],
    // name: '', dataSource: '', 
    // answer: '', justification: '', comments:[]
    highRiskJursdictionHits:[],
    // name: '', dataSource: '', 
    // answer: '', justification: '', comments:[]
    adverseNewsHits:[],
    // title: '', description: '', url: '',
    // answer: '', justification: '', comments:[]
  }); 
  const [kycData, setKycData] = useState(); 

  const [loaded, setLoaded] = useState(true);
  const [fetchDataAgain, setFetchDataAgain] = useState(false); 
  const [assessmentRunning, setAssessmentRunning] = useState(false);
  const [allManagers, setAllManagers] = useState([])
  const userRole = decodedToken.role
  let lastSubmittedEmail;

  useEffect(() => {
    const fetchData = async () => { 
      let allManagers = (await getAllUsers(token)).data
      allManagers = allManagers.filter(users => users.role !== 'executive' && users.role !== 'viewer');

      const currentKycData = await getkyc(userId, token).then(response => response.data) 
      setAllManagers(allManagers) 
      setKycData(currentKycData)
      

      let kycRiskDataList = await getkycRiskAssessment(userId, token).then(response => response.data);
      let kycRiskData = kycRiskDataList[0]
      // console.log(kycRiskDataList)
      // console.log('kycRiskDataList')
      // let kycRiskData = kycRiskDataList[0],  verify if this indeed returns the oldest one?
 
      setAllFormData(kycRiskDataList)
      let pepResultsFull = [], crimeResultsFull =[], highRiskJursdictionResultFull =[], sanctionsResultsFull =[], adverseNewsResultsFull =[]; //this to resolve scope issue

      // 1. Call sanctions.io API
      if (kycRiskDataList.length == 0 || fetchDataAgain === true) {
        setAssessmentRunning(true);
        console.log('fetching data for 1st time...')
        setFormData({ kycaml_client_id: userId, created_at: '', selectedDate: '', sourceOfWealthDocumentURL: '', otherDocumentsURL: '', riskLevel: '', nextRescreeningDate: '', status: 'new', singaporeGovernmentEntity: '', listedOnStockExchange: '', singaporeFI: '', nonSingaporeFI: '', politicallyExposedPerson: '', countryCountermeasureFATF: '', countryInadequateAML_CFT: '', higherRiskSector: '', highRiskJurisdictionFATF: '', credibleBodyIdentifiedCountry: '', investmentVehicle : { response: '', justification: '', newComment:'', comments:[] }, customerPensionSuperannuation: { response: '', justification: '', newComment:'', comments:[]}, unverifiedBusinessActivity: { response: '', justification: '', newComment:'', comments:[] }, unverifiedCorporateStructure: { response: '', justification: '', newComment:'', comments:[] }, unverifiedFinancialActivity: { response: '', justification: '', newComment:'', comments:[] }, unusualOwnershipStructure: { response: '', justification: '', newComment:'', comments:[] }, unusualBusinessCircumstances: { response: '', justification: '', newComment:'', comments:[] }, nomineeShareholdersBearer: { response: '', justification: '', newComment:'', comments:[] }, anonymousTranscations: { response: '', justification: '', newComment:'', comments:[] }, unknownPaymentSources: { response: '', justification: '', newComment:'', comments:[] }, pepHits:[], crimesHits:[], sanctionsHits:[], highRiskJursdictionHits:[], adverseNewsHits:[], });
        toast.info(`Assessment running...`);


        let individualOrEntityConnectedPartyToCheck = [currentKycData]
        if (currentKycData.type == 'entity' && currentKycData?.details?.connectedParty.length != 0) {
          individualOrEntityConnectedPartyToCheck = individualOrEntityConnectedPartyToCheck.concat(currentKycData?.details?.connectedParty);  // gather all additional connected parties (and verify if the PEP relations are legit)
        } 

        individualOrEntityConnectedPartyToCheck.map( async (entityOrParty, index) => {
          // 1a. Adjust entity search type
          let partyType = 'individual' // currentKycData.type
          if (currentKycData.type == 'entity' && index == 0) {
            partyType = 'entity'
          }

          let relevantPartyType = ''
          if (index !== 0) {
            relevantPartyType = ` (Relevant Party ${index})` //let relevantPartyTypes = [' (Relevant Party)'];  // if (entityOrParty.authorisedSignatory) { //     relevantPartyTypes.push('(authorisedSignatory)'); // } // if (entityOrParty.connectedParty) { //     relevantPartyTypes.push('(connectedParty)'); // } // if (entityOrParty.ultimateBeneficialOwner) { //     relevantPartyTypes.push('(ultimateBeneficialOwner)'); // } relevantPartyType = relevantPartyTypes.join(' ');
          }

          // 1b. Make API calls & save data in results[]
          const pepPromise = sanctionsHits(entityOrParty.name, 'PEP', partyType, token) 
          const crimePromise = sanctionsHits(entityOrParty.name, 'INTERPOL,FBI', partyType, token) 
          const sanctionsPromise = sanctionsHits(entityOrParty.name, 'SANCTIONS-LISTS', partyType, token) 
          const highRiskJursdictionPromise = sanctionsHits(entityOrParty.name, 'FinCEN', partyType, token) 
          const adverseNewsPromise = adverseNewsHits(entityOrParty.name, ' ', partyType, token) 
          

          


          let pepResults =[], crimeResults =[], highRiskJursdictionResults =[], sanctionsResults =[], adverseNewsResults =[]; //this to resolve scope issue
          try {
            const results = await Promise.all([pepPromise, crimePromise, highRiskJursdictionPromise, sanctionsPromise, adverseNewsPromise]);
            [pepResults, crimeResults, highRiskJursdictionResults, sanctionsResults, adverseNewsResults] = results.map(result => result || []); // Use empty array if result is undefined
  
            // return new array of objects with selected properties only
            pepResults?.data?.result.map( person => { 
              if (!person.position) { return }
                person?.position.map(position => {
                  let pepData = {
                    name: person.name,
                    si_identifier : person.si_identifier, 
                    title: `${position?.title || ''}, ${position?.organization || ''}` + relevantPartyType, // (connected party),,
                    // title: `${position.title}, ${position.organization}` + relevantPartyType, // (connected party),,
                    startDate : position.start_date || 'NA',
                    endDate : position.end_date || 'NA',
                    response: 'No',
                    justification: '',
                    newComment :  '',
                    comments :  []
                  }
                  pepResultsFull.push(pepData)
                })
            })
            // pepResultsFull.push(...pepResultsArray); 
            // pepResultsFull = pepResultsFull.concat(pepResultsArray);  // The issue here is that you're using a global variable pepResultsFull inside a function scope, but you're not properly updating it. In JavaScript, when you use an assignment operator (=) inside a function for a variable that exists in an outer scope, it creates a new local variable instead of modifying the global one.

            const crimeResultsArray = []
            crimeResults?.data.result.map( crime => { 
                  let crimeData = {
                    name: crime.name + relevantPartyType,
                    dataSource: crime.data_source?.name,
                    si_identifier : crime.si_identifier, 
                    federal_register_notice: crime.federal_register_notice,
                    source_information_url: crime.source_information_url,
                    response: 'No',
                    justification: '',
                    newComment :  '',
                    comments : []
                  }
                  crimeResultsArray.push(crimeData)
            })
            crimeResultsFull.push(...crimeResultsArray);
            crimeResultsArray.filter(crime => crime?.name.includes('undefined'))

            const sanctionsResultsArray = []
            sanctionsResults?.data.result.map( sanction => {
                  let sanctionsData = {
                    name: sanction.name + relevantPartyType,
                    dataSource: sanction.data_source.name,
                    si_identifier : sanction.si_identifier, 
                    source_list_url: sanction.source_list_url,
                    response: 'No',
                    justification: '',
                    newComment :  '',
                    comments :  []
                  }
                  sanctionsResultsArray.push(sanctionsData)
            })
            sanctionsResultsFull.push(...sanctionsResultsArray);
            
            const highRiskJursdictionResultsArray = [] 
            highRiskJursdictionResults?.data.result.map( highRiskJursdiction => {
              let highRiskData = {
                name: highRiskJursdiction.name + relevantPartyType,
                dataSource: highRiskJursdiction.data_source.name,
                si_identifier : highRiskJursdiction.si_identifier, 
                source_list_url: highRiskJursdiction.source_list_url,
                response: 'No',
                justification: '',
                newComment :  '',
                comments :  []
              }
              highRiskJursdictionResultsArray.push(highRiskData)
            })
            highRiskJursdictionResultFull.push(...highRiskJursdictionResultsArray);

            adverseNewsResults?.data.map( adverseNews => {
              let adverseNewsData = {
                title: adverseNews.title + relevantPartyType,
                url: adverseNews.url,
                description : adverseNews.description,
                response: 'No',
                justification: '',
                newComment :  '',
                comments :  []
              }
              adverseNewsResultsFull.push(adverseNewsData)
            })


          } catch(e) {
            console.error("error fetching sanctions.io data")
          } 
      })
        
        await new Promise(r => setTimeout(r, 2500)); // by right should return a promise in the above .map then can await it.
        // 2. automated checks
        try {
          const emptyFormData = automatedChecks(userId, currentKycData)
          
        // // consolidate all data from data fetch and save as new riskAssessment entry 
        kycRiskData = {
          ...emptyFormData, 
          pepHits: pepResultsFull.reverse(),
          crimesHits: crimeResultsFull.reverse(), 
          sanctionsHits: sanctionsResultsFull.reverse(), 
          highRiskJursdictionHits: highRiskJursdictionResultFull.reverse(), // reverse so relevvant party 2 comes last, or can unshift to append start of array above
          adverseNewsHits: adverseNewsResultsFull.reverse(), 
          selectedDate: DateTime.now().setZone('Asia/Singapore').toFormat("dd-MM-yyyy, HH:mm:ss"),
          created_at: DateTime.now().setZone('Asia/Singapore').toFormat("dd-MM-yyyy, HH:mm:ss")           // iife javascript design pattern , Immediately Invoked Function Expression 
        } 

        console.log(kycRiskData)
        console.log('kycRiskData')

        await createkycRiskAssessment('', token) // somehow if not for this request, the 2nd request wont get the pepHits list 
        // its ok to refactor, its training of general systems visualising and SOP flow creation neural network. thats not a waste. (read legacy code pdf too) 
        // the practice of refactoring old working long code is a skill to hone as well.

        await createkycRiskAssessment(kycRiskData, token)
        allFormData.push(kycRiskData)
        setAllFormData(allFormData) 
      } catch(e) {
        console.error("error saving newest KYC Risk data")
      }
    } 
      lastSubmittedEmail = kycRiskData?.managerReviewEmail
      setFormData({
        ...kycRiskData,
      })
      setAssessmentRunning(false);
      if (fetchDataAgain || kycRiskDataList.length == 0) {
        toast.success(`Assessing complete for: ${DateTime.now().setZone('Asia/Singapore').toFormat("dd-MM-yyyy, HH:mm:ss")}`);
      }
      setLoaded(false);
      setFetchDataAgain(false)
    } 

    fetchData()
  }, // console.error('Error fetching KYC data:', error);
  [fetchDataAgain]);

  // const handleDateChange = (e) => {
  //   const { name, value } = e.target;
  //     // let kycRiskData = kycRiskDataList.filter( kycRisk => kycRisk.created_at === DateTime.now().setZone('Asia/Singapore').toFormat("dd-MM-yyyy, HH:mm:ss") )[0];
  //   const displayForm = allFormData.filter(pastFormData => pastFormData.created_at == value)[0]
  //   console.log(value)
  //   setFormData(displayForm)

  //   toast.success ("Changed to: " + value);
  // }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const newFormData = {  // instead of setState, prevData => {...prevData, new: newValue}
      ...formData,         // do this, to ensure is kept up to date
      [name] : value  
    }

    setFormData(newFormData) 
    checkRiskStatus(newFormData)
  }

  const handleNestedInputChange = (e, nestedName) => {
    const { name, value } = e.target; 

    const newFormData = {  // instead of setState, prevData => {...prevData, new: newValue}
      ...formData,         // do this, to ensure is kept up to date
      [name] : {
        ...formData[name],
        [nestedName] : value
      }  
    }
    setFormData(newFormData) 

  //   setFormData((prevState) => ({
  //     ...prevState,
  //     [name]: {
  //         ...prevState[name],  // Keep existing nested object properties
  //         [nestedName]: value  // Update the `justification` property
  //     }
  // }));
    checkRiskStatus(newFormData)
  }

  const handleNestedArrayInputChange = (e, nestedName, index) => {
    let { name, value } = e.target; 
    name = name.split(' ')[0]
    const newArrayResult = [...formData[name]]
    newArrayResult[index][nestedName] = value

    const newFormData = {  
      ...formData,          
      [name]: newArrayResult
    }
    setFormData(newFormData) 
    checkRiskStatus(newFormData)
  }

  const handleSubmitNestedArrayComment = async (e, formName, nestedName, index) => {
    if (formData[formName][index][nestedName].trim() == '') { return }
 
    const newCommentEntry = {
      created_at: formData.created_at,
      kycaml_client_id: userId,
      question: formName + ' ' + index.toString(),
      content : formData[formName][index][nestedName],
      name : decodedToken.name,
      commented_at : DateTime.now().setZone('Asia/Singapore').toFormat("dd-MM-yyyy, HH:mm:ss"),
      comment_id : uuidv4()  
    }
    await createKycNestedSanctionComment(newCommentEntry, token)   
    formData[formName][index].comments.push(newCommentEntry);
    const newCommentArray = formData[formName][index].comments

    const newPepArray = [...formData[formName]]
    newPepArray[index].comments = newCommentArray
    newPepArray[index].newComment = ''

    setFormData((prevState) => ({
      ...prevState,
      [formName]: newPepArray
  }));

  }


  const handleSubmitComment = async (e, nestedName, inputName) => {
    if (formData[inputName][nestedName].trim() == '') { return }

    const newCommentEntry = {
      created_at: formData.created_at,
      kycaml_client_id: userId,
      question: inputName,
      content : formData[inputName][nestedName],
      name : decodedToken.name,
      commented_at : DateTime.now().setZone('Asia/Singapore').toFormat("dd-MM-yyyy, HH:mm:ss"),
      comment_id : uuidv4() //random ID, for future if allow deletion
    }
    await createKycComment(newCommentEntry, token)  
    formData[inputName].comments.push(newCommentEntry);

    setFormData((prevState) => ({
      ...prevState,
      [inputName]: {
          ...prevState[inputName],  // Keep existing nested object properties
          [nestedName]: formData[inputName].comments,     // .push should be on its own satement, not assignment
          // [nestedName]: [...formData[inputName].comments, newCommentEntry],   // push mutates the array, so runs double
          newComment: ''   
      }
  }));

  }

  const handleSaveForm = async (e) => {   
    updatekycRiskAssessment(formData, token) 
    toast.success(`Assessment saved!`);
  }   

  // const checkFormValidity = () => {
  //   const isValid = Object.values(formData).every(value => value.trim() !== '');

  //   if ()
  //   setIsFormValid(isValid);
  // };

  const handleSubmitForReview = async (e) => { 
    let newFormData = {
      ...formData,
      status: 'waiting for review', 
      submitPerson : decodedToken.name,
      submitDate : DateTime.now().setZone('Asia/Singapore').toFormat("dd-MM-yyyy, HH:mm:ss"),
    }

    // submittedBy
    updatekycRiskAssessment(newFormData, token)
    updatekycStatus({formStatus: "pending_manager_review", id: userId }, token)
    toast.success(`Submitted for review!`);
    
    sendEmailNotification({
      email: formData.managerReviewEmail,
      subject: "KYC Risk Assessment Form pending review - iCompass",
      message: `
        <div style="max-width: 600px; margin: 0 auto; border: 2px solid #FF8C00; padding: 20px; text-align: center;">
          <h1 style="margin-bottom: 20px; text-decoration: underline;"> Risk Assessment Approval Pending</h1>
          <p>${decodedToken.name} has submitted a risk assessment form, ${kycData.name} (${kycData.type}), for your approval. 
            <a href='${window.location.href}'> Click to view form </a>
          </p>
          <img src="https://icompass-backend-dev-fhagh5hccpf2ebh9.southeastasia-01.azurewebsites.net/images/from_icompass" alt="iCOMPASS logo" style="display: block; margin: 20px auto; width: 200px; height: auto; max-width: 100%;">
          <p style="font-style: italic; margin-top: 20px;">Please do not reply to this email</p>
        </div> `
    })

    setFormData(newFormData)
    
    createNotification({
      type: 'KYC',
      text: `${kycData.name} status has changed to Pending Manager Review`,
      date: DateTime.now().toFormat('dd-MM-yyyy HH:mm:ss')
  }, token)
  }  


  const handleFileChange = async (e, fileType) => {
    const file = e.target.files[0];
    const {name} = e.target
    if (!file) { return }

    try {
      const data = new FormData();
      data.append("file", file);
      const filename = `${file.name}`;
      const res = await uploadFile(data, filename,token); 

      if (res) { 
        setFormData(prevState => ({
          ...prevState,
          [name] : res.data.url
          // identificationDocumentLink :  res.data.url // name refer to 'input name' property.
      }))

      } else {
        toast.error("Invalid file type!") 
      }



    } catch (e) {
      toast.error(`Invalid file type!`);
    }
  }

  const checkRiskStatus = (newFormData) => { 
    // 1. if all no, AND SDD > 0, risk = 'low'
    const SDD_Questions = ['singaporeGovernmentEntity', 'listedOnStockExchange', 'singaporeFI', 'nonSingaporeFI', 'investmentVehicle', 'customerPensionSuperannuation']
    let SSD_yes_count = 0 


    SDD_Questions.forEach(SSD_qn_key => {
      if (newFormData[SSD_qn_key] == 'Yes' || newFormData[SSD_qn_key]?.response == 'Yes' ) { SSD_yes_count++ }  //  const SSD_yes_count = SDD_Questions.reduce((count, question) => formData[question] === 'Yes' ? count + 1 : count, 0);
    })  
    
    // 2a. if EDD > 0, risk = 'high' instantly, even if justified
    const EDD_Questions = ['politicallyExposedPerson', 'countryCountermeasureFATF', 'countryInadequateAML_CFT', 'unverifiedBusinessActivity', 'unverifiedCorporateStructure', 'unverifiedFinancialActivity']
    let EDD_yes_count = 0 

    EDD_Questions.forEach(EDD_qn_key => { 
      if (newFormData[EDD_qn_key]?.response == 'Yes' || newFormData[EDD_qn_key] == 'Yes') { EDD_yes_count++ }
    })

    // 2b. if Yes high, else justified = medium
    const EDD_Hits_Questions = ['pepHits', 'crimesHits', 'sanctionsHits', 'highRiskJursdictionHits', 'adverseNewsHits']
    let EDD_Hits_yes_count_Justified = 0
    let EDD_Hits_yes_count_NotJustified = 0
    
    EDD_Hits_Questions.forEach(EDD_qn_justification_key => {
      newFormData[EDD_qn_justification_key].forEach(hit => {
        if (hit?.response === 'Yes' && hit?.justification.trim() == '') { 
          EDD_Hits_yes_count_NotJustified++ 
        } else if (hit?.response === 'Yes' && hit?.justification.trim() !== '') { 
          EDD_Hits_yes_count_Justified++ 
        }
      })
    })

    // 3a. if AA > 0, risk = 'Medium', "High risk if yes and unjustified. but medium risk if justified"
    const AA_Questions = ['unusualOwnershipStructure', 'unusualBusinessCircumstances', 'nomineeShareholdersBearer', 'anonymousTranscations', 'unknownPaymentSources']
    let AA_yes_count_Justified = 0
    let AA_yes_count_NotJustified = 0
 
    AA_Questions.forEach(AA_qn_key => {
      if (newFormData[AA_qn_key]?.response === 'Yes' && newFormData[AA_qn_key]?.justification.trim() == '') { 
        AA_yes_count_NotJustified++ 
      } else if (newFormData[AA_qn_key]?.response === 'Yes' && newFormData[AA_qn_key]?.justification.trim() !== '') { 
        AA_yes_count_Justified++ 
      }
    })

    // 3b. if AA_automated > 0 , Risk = 'High',      
    const AA_automated_Questions = ['higherRiskSector', 'highRiskJurisdictionFATF', 'credibleBodyIdentifiedCountry' ]
    let AA_automated_yes_count = 0

    AA_automated_Questions.forEach(AA_automated_qn_key => { 
      if (newFormData[AA_automated_qn_key]?.response == 'Yes' || newFormData[AA_automated_qn_key] == 'Yes') { AA_automated_yes_count++ }
    })

    // 2a. if EDD > 0, risk = 'high' instantly, even if justified
    // 3b. if AA_automated > 0 , Risk = 'High',      

    // 3a. if AA > 0, risk = 'Medium', "High risk if yes and unjustified. but medium risk if justified"
    // 2b. if Yes high, else justified = medium

    // chatgpt this to see how simplify logic more elegant, eg allCountsZero()
    let riskLevel = 'Standard'
    if (EDD_yes_count === 0 && EDD_Hits_yes_count_Justified == 0 && EDD_Hits_yes_count_NotJustified == 0
      && AA_automated_yes_count == 0 && AA_yes_count_Justified == 0 && AA_yes_count_NotJustified == 0
      && SSD_yes_count > 0) {
        riskLevel = 'Low'
      } 
    else if (EDD_yes_count > 0 || EDD_Hits_yes_count_NotJustified > 0 || AA_automated_yes_count > 0 || AA_yes_count_NotJustified > 0 ) {
        riskLevel = 'High';
    }  
    else if (EDD_yes_count === 0 && EDD_Hits_yes_count_Justified == 0 && EDD_Hits_yes_count_NotJustified == 0
      && AA_automated_yes_count == 0 && AA_yes_count_Justified == 0 && AA_yes_count_NotJustified == 0
      && SSD_yes_count == 0) {
        riskLevel = 'Standard'
    }
    else if (EDD_Hits_yes_count_Justified > 0 ||  AA_yes_count_Justified > 0) {
      riskLevel = 'Standard';
    }  
    else {
      riskLevel = "edge case!"
    }

    // console.log('SSD_yes_count:', SSD_yes_count);
    // console.log('EDD_yes_count:', EDD_yes_count);
    // console.log('EDD_Hits_yes_count_Justified:', EDD_Hits_yes_count_Justified);
    // console.log('EDD_Hits_yes_count_NotJustified:', EDD_Hits_yes_count_NotJustified);
    // console.log('AA_automated_yes_count:', AA_automated_yes_count);
    // console.log('AA_yes_count_Justified:', AA_yes_count_Justified);
    // console.log('AA_yes_count_NotJustified:', AA_yes_count_NotJustified);
    // console.log("the risk level is: ",riskLevel)

    newFormData = {
      ...newFormData,
      riskLevel : riskLevel  
    }
    setFormData(newFormData)
  }

  const getRiskTag = (risk) => {
    const riskClasses = {
      Low: 'text-green-500',
      Standard: 'text-orange-500',
      High: 'text-red-500',
      Edge: 'text-purple-600', 
    };
    const riskClass = riskClasses[risk] || riskClasses['Edge']; // Default to 'Edge' for unknown risks
    return <span className={`bg-gray-200 py-2 px-4 border border-b-gray-300 ${riskClass}`}>{risk}</span>;
  };

  const getStatusTag = (status) => {
    if (status == "new" || status == "waiting for review") {
      return <span className="text-orange-500"> Yet to be decided by manager</span> 
    }
    else if (status == 'accepted') {
      return <span className="text-green-500">Accepted</span> 
    }
    else if (status == 'rejected') {
      return <span className="text-red-500">Rejected</span> 
    } else {
      return <span className="text-purple-600">Edge case!!!</span> 
    }
  }


  const handleStatusUpdate = (e, updatedStatus) => {

      let nextRescreeningDate = ''
      let onGoingMonitoring=''
      let currentDate = DateTime.now().setZone('Asia/Singapore');
      let maxPage = 2

      if (updatedStatus == 'accepted') {
        if (formData.riskLevel == 'Low') {  nextRescreeningDate = currentDate.plus({ years: 3 }).toFormat("dd-MM-yyyy") } 
        if (formData.riskLevel == 'Standard') {  nextRescreeningDate = currentDate.plus({ years: 2 }).toFormat("dd-MM-yyyy") } 
        if (formData.riskLevel == 'High') {  nextRescreeningDate = currentDate.plus({ years: 1 }).toFormat("dd-MM-yyyy") } 
        if (decodedToken.companyId == 'Inspro_Insurance') {  nextRescreeningDate = currentDate.plus({ years: 1 }).toFormat("dd-MM-yyyy") } 
        onGoingMonitoring = true
        maxPage = 3
      }

      const newFormData = {
          ...formData,
          status : updatedStatus,
          screenedDate :  DateTime.now().setZone('Asia/Singapore').toFormat("dd-MM-yyyy, HH:mm:ss"),
          nextRescreeningDate: nextRescreeningDate,
          approvalPerson: decodedToken.name,
          approvalDate :  DateTime.now().setZone('Asia/Singapore').toFormat("dd-MM-yyyy, HH:mm:ss"),
        }

      setFormData(newFormData)
      updatekycStatus({
        formStatus: updatedStatus,
        id: userId,
        nextRescreeningDate: nextRescreeningDate,
        onGoingMonitoring: onGoingMonitoring,
        maxPage: maxPage
       }, token)

      updatekycRiskAssessment(newFormData, token)


      sendEmailNotification({
        email: kycData.email,
        subject: "KYC Risk Assessment Form status update - iCompass",
        message: `
          <div style="max-width: 600px; margin: 0 auto; border: 2px solid #FF8C00; padding: 20px; text-align: center;">
            <h1 style="margin-bottom: 20px; text-decoration: underline;"> Risk Assessment Status update</h1>
            <p>${decodedToken.name} has updated the status of risk assessment form for, ${kycData.name} (${kycData.type}) to ${updatedStatus}. 
              <a href='${window.location.href}'> Click to view form </a>
            </p>
            <img src="https://icompass-backend-dev-fhagh5hccpf2ebh9.southeastasia-01.azurewebsites.net/images/from_icompass" alt="iCOMPASS logo" style="display: block; margin: 20px auto; width: 200px; height: auto; max-width: 100%;">
            <p style="font-style: italic; margin-top: 20px;">Please do not reply to this email</p>
          </div> `
      })

      createNotification({
        type: 'KYC',
        text: `${kycData.name} status has changed to ${updatedStatus}`,
        date: DateTime.now().toFormat('dd-MM-yyyy HH:mm:ss')
    }, token)
  }

  let qnCounter = 1

  return (
<div className={`max-w-6xl mx-auto mt-3 p-5 rounded-lg shadow-lg ${readOnly ? 'bg-gray-200' : 'bg-white '}`}>
  {loaded ? <div className='h-screen w-screen'> Loading... </div> : (
  <div> 
        

      {/* SSD 6.12, 6.16c-d, 6.16e, 6.16f, 6.16g, 7.1bi, qn1-6  */}
    <h1 className='font-semibold underline text-2xl m-2 mb-4'>KYC Form - Risk Assessment </h1>

    {userType == 'entity' && 
      <>
      <QuestionCard disabled={readOnly}  inputName={"singaporeGovernmentEntity"} handleInputChange={handleInputChange} question={"Is the customer a Singapore government entity?"} 
        automated={true} questionNumber={qnCounter++} justification={false} value={formData.singaporeGovernmentEntity}/>   
      <QuestionCard disabled={readOnly}  inputName={"listedOnStockExchange"} handleInputChange={handleInputChange} question={"Is the customer listed on the stock exchange and subject to regulatory disclosure requirements?"} 
        automated={true} questionNumber={qnCounter++} justification={false} value={formData.listedOnStockExchange}/>  
      <QuestionCard disabled={readOnly}  inputName={"singaporeFI"} handleInputChange={handleInputChange} question={<>Is the customer an FI incorporated or established <strong>in Singapore</strong> that is subject to and supervised for compliance with AML/CFT requirements consistent with FATF standards?</>} 
        automated={true} questionNumber={qnCounter++} justification={false} value={formData.singaporeFI}/>  
      <QuestionCard disabled={readOnly}  inputName={"nonSingaporeFI"} handleInputChange={handleInputChange} question={<> Is the customer an FI incorporated or established in a country <strong>outside of Singapore</strong> that is subject to and supervised for compliance with AML/CFT requirements consistent with FATF standards" </>} 
        automated={true} questionNumber={qnCounter++} justification={false} value={formData.nonSingaporeFI}/>  
      <QuestionCard disabled={readOnly}  inputName={"investmentVehicle"} handleInputChange={handleNestedInputChange} question={ "Is the customer an investment vehicle where the managers are financial institutions in Singapore or incorporated or established in a country outside of Singapore that is subject to and supervised for compliance with AML/CFT requirements consistent with FATF standards?"} 
        automated={false} questionNumber={qnCounter++} justification={true} handleSubmitComment={handleSubmitComment} value={formData.investmentVehicle}/>  
      <QuestionCard disabled={readOnly}  inputName={"customerPensionSuperannuation"} handleInputChange={handleNestedInputChange} question={"Does the customer provide pension, superannuation or similar scheme that provides retirement benefits to employees, where contributions are made by way of deduction from wages, and the scheme rules do not permit the assignment of a member’s interest under the scheme?"} 
        automated={false} questionNumber={qnCounter++} justification={true} handleSubmitComment={handleSubmitComment} value={formData.customerPensionSuperannuation}/>  
      </>
      }

      {/* EDD 8.3, 8.6a, 8.6b, 8.6ci, 8.6cii, 8.6ciii, q7-25 */}
      <QuestionCard disabled={readOnly}  inputName={"politicallyExposedPerson"} handleInputChange={handleInputChange} question={"Is the customer or its beneficial owners politically exposed person, or a family member or close associate of a political exposed person?"} 
        automated={true} questionNumber={qnCounter++} justification={false} value={formData.politicallyExposedPerson}/>  
      <QuestionCard disabled={readOnly}  inputName={"countryCountermeasureFATF"} handleInputChange={handleInputChange} question={"Does the customer come from a country or jurisdiction in relation to which the FATF has called for countermeasures?"} 
        automated={true} questionNumber={qnCounter++} justification={false} value={formData.countryCountermeasureFATF}/>  
      <QuestionCard disabled={readOnly}  inputName={"countryInadequateAML_CFT"} handleInputChange={handleInputChange} question={"Does the customer or any beneficial owner come from a country or jurisdiction known to have inadequate AML/CFT measures, as determined by the foreign regulatory authorities or by the authority (MAS)?"} 
        automated={true} questionNumber={qnCounter++} justification={false} value={formData.countryInadequateAML_CFT}/>  

{userType == 'entity' && 
    <>
      <QuestionCard disabled={readOnly}  inputName={"unverifiedBusinessActivity"} handleInputChange={handleNestedInputChange} question={"Is the customer a legal person for which we are unable to establish if it has any ongoing, apparent or visible business activity?"} 
        automated={false} questionNumber={qnCounter++} justification={true} handleSubmitComment={handleSubmitComment} value={formData.unverifiedBusinessActivity}/>  
      <QuestionCard disabled={readOnly}  inputName={"unverifiedCorporateStructure"} handleInputChange={handleNestedInputChange} question={"Is the customer a legal person for which we are unable to establish its business purpose for its corporate structure or arrangement?"} 
        automated={false} questionNumber={qnCounter++} justification={true} handleSubmitComment={handleSubmitComment} value={formData.unverifiedCorporateStructure}/>  
      <QuestionCard disabled={readOnly}  inputName={"unverifiedFinancialActivity"} handleInputChange={handleNestedInputChange} question={"Is the customer a legal person where we are unable to establish if it has any substantive financial activity in its interaction with us?"} 
        automated={false} questionNumber={qnCounter++} justification={true} handleSubmitComment={handleSubmitComment} value={formData.unverifiedFinancialActivity}/>
    </>
}
    <>
      {/* EDD qn13-17 */}
      <SanctionsioScreeningCard disabled={readOnly} questionNumber={qnCounter++} handleSubmitComment={handleSubmitNestedArrayComment} result={formData} 
        inputName={"pepHits"} pepExtra={kycData} handleInputChange={handleNestedArrayInputChange} question={"Were there any hits for PEP screening?"} 
        tableHeaders={[
          {displayTitle: 'Name/title', width : 'w-3/12', dataField: (result) => `${result?.name} \n ${result?.title || ''}` },
          {displayTitle: 'Position start', width : 'w-1.5/12', dataField: (result) => result?.startDate },
          {displayTitle: 'Position end', width : 'w-1.5/12', dataField: (result) => result?.endDate },
          {displayTitle: 'True Hit?', width : 'w-1/12', dataField: null },
          {displayTitle: 'Justification', width : 'w-5/12', dataField: null },
        ]}
      />
      <SanctionsioScreeningCard disabled={readOnly} questionNumber={qnCounter++} handleSubmitComment={handleSubmitNestedArrayComment} result={formData} 
        inputName={"crimesHits"} handleInputChange={handleNestedArrayInputChange} question={"Were there any hits for crime?"} 
        tableHeaders={[
          {displayTitle: 'Name / DataSource', width : 'w-2/12', dataField: (result) => `${result.name} \n ${result.dataSource}` },
          {displayTitle: 'Remarks', width : 'w-4/12', 
            dataField: (result) => {
              return ( // you can either return a plain string like above, or return <> JSX </> with 'return'
                <>
                  {result?.federal_register_notice?.substring(0, 280) + (result?.federal_register_notice?.length > 280 ? '...' : '')} {/* 280 characters approx 70 words */}
                  <a className='text-blue-500' href={result?.source_information_url} target="_blank" rel="noopener noreferrer"> Link </a> {/* rel="noopener noreferrer", byppasses the API block somehow */} 
                </> );
            }}, 
          {displayTitle: 'True Hit?', width : 'w-1/12', dataField: null },
          {displayTitle: 'Justification', width : 'w-5/12', dataField: null },
        ]}
      />
      <SanctionsioScreeningCard disabled={readOnly} questionNumber={qnCounter++} handleSubmitComment={handleSubmitNestedArrayComment} result={formData} 
        inputName={"sanctionsHits"} handleInputChange={handleNestedArrayInputChange} question={"Were there any hits for sanctions?"} 
        tableHeaders={[
          {displayTitle: 'Name', width : 'w-3/12', dataField: (result) => result.name },
          {displayTitle: 'DataSource', width : 'w-3/12',
              dataField: (result) => {
                return ( 
                  <>
                    {result.dataSource} 
                    <a className='text-blue-500' href={result?.source_list_url} target="_blank" rel="noopener noreferrer"> Link </a>
                  </> );
                }},
          {displayTitle: 'True Hit?', width : 'w-1/12', dataField: null },
          {displayTitle: 'Justification', width : 'w-5/12', dataField: null },
        ]}
      />
      <SanctionsioScreeningCard disabled={readOnly} questionNumber={qnCounter++} handleSubmitComment={handleSubmitNestedArrayComment} result={formData} 
        inputName={"highRiskJursdictionHits"} handleInputChange={handleNestedArrayInputChange} question={"Were there any hits for High Risk Jurisdiction?"} 
        tableHeaders={[
          {displayTitle: 'Name', width : 'w-3/12', dataField: (result) => result.name },
          {displayTitle: 'Data Source', width : 'w-3/12', 
            dataField: (result) => {
              return ( 
                <>
                  {result.dataSource} 
                  <a className='text-blue-500' href={result?.source_list_url} target="_blank" rel="noopener noreferrer"> {result.source_list_url} </a>
                </> );
              }},
          {displayTitle: 'True Hit?', width : 'w-1/12', dataField: null },
          {displayTitle: 'Justification', width : 'w-5/12', dataField: null },
        ]}
      />
      <SanctionsioScreeningCard disabled={readOnly} questionNumber={qnCounter++} handleSubmitComment={handleSubmitNestedArrayComment} result={formData} 
        inputName={"adverseNewsHits"} handleInputChange={handleNestedArrayInputChange} question={"Are there any adverse news?"} 
        tableHeaders={[
          {displayTitle: 'Title', width : 'w-2.5/12', dataField: (result) => result.title },
          {displayTitle: 'Description', width : 'w-3.5/12', 
            dataField: (result) => {
              return ( 
                <>
                  {result.description} 
                  <a className='text-blue-500' href={result?.url} target="_blank" rel="noopener noreferrer"> Link </a>
                </> );
              }},
          {displayTitle: 'True Hit?', width : 'w-1/12', dataField: null },
          {displayTitle: 'Justification', width : 'w-5/12', dataField: null },
        ]}
      /> 
    </>

    <>
      {/* AA 8-2a, 8-2a, 8-2a, 8-2a, 8-2b, 8-2b,8-2c, 8-2c, qn18-25  */}
      {userType == 'entity' && 
      <>
      <QuestionCard disabled={readOnly}  inputName={"higherRiskSector"} handleInputChange={handleInputChange} question={"Is the customer from higher risk sectors identified in Singapore's NRA or by the CMI?"} 
        automated={true} questionNumber={qnCounter++} justification={false} value={formData.higherRiskSector}/>  
      <QuestionCard disabled={readOnly}  inputName={"unusualOwnershipStructure"} handleInputChange={handleNestedInputChange} question={"Is the ownership structure of the customer unusual or excessively complex given the nature of the business?"} 
        automated={false} questionNumber={qnCounter++} justification={true} handleSubmitComment={handleSubmitComment} value={formData.unusualOwnershipStructure}/>  
      </> }
      <QuestionCard disabled={readOnly}  inputName={"unusualBusinessCircumstances"} handleInputChange={handleNestedInputChange} question={"Is the business relations conducted under unusual circumstances? (Eg. significant unexplained geographic distance between the Company and Customer.)"} 
        automated={false} questionNumber={qnCounter++} justification={true} handleSubmitComment={handleSubmitComment} value={formData.unusualBusinessCircumstances}/>  
      
      {userType == 'entity' && 
      <>
      <QuestionCard disabled={readOnly}  inputName={"nomineeShareholdersBearer"} handleInputChange={handleNestedInputChange} question={"Does the customer have nominee shareholders or shares in bearer form?"} 
        automated={false} questionNumber={qnCounter++} justification={true} handleSubmitComment={handleSubmitComment} value={formData.nomineeShareholdersBearer}/>  
      </> }

      <QuestionCard disabled={readOnly}  inputName={"highRiskJurisdictionFATF"} handleInputChange={handleInputChange} question={"Is the customer from countries or jurisdictions which have a higher level of corruption, organised crime or inadequate AML/CFT measures as identified by FATF?"} 
        automated={true} questionNumber={qnCounter++} justification={false} value={formData.highRiskJurisdictionFATF}/>  
      <QuestionCard disabled={readOnly}  inputName={"credibleBodyIdentifiedCountry"} handleInputChange={handleInputChange} question={"Is the customer from countries identified by credible bodies (Transparency International) as having significant levels of corruption, terrorism financing or other criminal activity?"} 
        automated={true} questionNumber={qnCounter++} justification={false} value={formData.credibleBodyIdentifiedCountry}/>  

      {userType == 'entity' && 
      <>
      <QuestionCard disabled={readOnly}  inputName={"anonymousTranscations"}handleInputChange={handleNestedInputChange} question={"Does the customer engage in anonymous transactions?"} 
        automated={false} questionNumber={qnCounter++} justification={true} handleSubmitComment={handleSubmitComment} value={formData.anonymousTranscations}/>  
      <QuestionCard disabled={readOnly}  inputName={"unknownPaymentSources"} handleInputChange={handleNestedInputChange} question={"Does the customer undertake frequent payments from unknown sources or unassociated third parties?"} 
        automated={false} questionNumber={qnCounter++}  justification={true} handleSubmitComment={handleSubmitComment} value={formData.unknownPaymentSources}/>  
      </> }
    </>
     
    <Card>
      <p className='mb-2 font-semibold'> Outcome </p> <hr/>
      <p className='my-4'> The risk level is determined to be: {getRiskTag(formData?.riskLevel)} </p>
      <SubmissionCard token={token} getRiskTag={getRiskTag} formData={formData} setFormData={setFormData} handleFileChange={handleFileChange} 
        getStatusTag={getStatusTag} handleStatusUpdate={handleStatusUpdate} handleSaveForm={handleSaveForm} 
        handleInputChange={handleInputChange} currentUrl={currentUrl} userRole={userRole}
        allManagers={allManagers} handleSubmitForReview={handleSubmitForReview} userId={userId}/>
    </Card>

  </div>
  )}
</div>
  );
};


export default _RiskAssessment 


function automatedChecks(userId, currentKycData) {
  let emptyFormData = ({ kycaml_client_id: userId, created_at: '', selectedDate: '', sourceOfWealthDocumentURL: '', otherDocumentsURL: '', riskLevel: 'Standard', nextRescreeningDate: '', 
    status: 'new', singaporeGovernmentEntity: 'No', listedOnStockExchange: 'No', singaporeFI: 'No', nonSingaporeFI: '', politicallyExposedPerson: '', countryCountermeasureFATF: '', countryInadequateAML_CFT: '', higherRiskSector: '', highRiskJurisdictionFATF: '', credibleBodyIdentifiedCountry: '', investmentVehicle : { response: '', justification: '', newComment:'', comments:[] }, customerPensionSuperannuation: { response: '', justification: '', newComment:'', comments:[]}, unverifiedBusinessActivity: { response: '', justification: '', newComment:'', comments:[] }, unverifiedCorporateStructure: { response: '', justification: '', newComment:'', comments:[] }, unverifiedFinancialActivity: { response: '', justification: '', newComment:'', comments:[] }, unusualOwnershipStructure: { response: '', justification: '', newComment:'', comments:[] }, unusualBusinessCircumstances: { response: '', justification: '', newComment:'', comments:[] }, nomineeShareholdersBearer: { response: '', justification: '', newComment:'', comments:[] }, anonymousTranscations: { response: '', justification: '', newComment:'', comments:[] }, unknownPaymentSources: { response: '', justification: '', newComment:'', comments:[] }, pepHits:[], crimesHits:[], sanctionsHits:[], highRiskJursdictionHits:[], adverseNewsHits:[], });
 
if (currentKycData.type == 'individual') {
  // qn1-4
    emptyFormData.singaporeGovernmentEntity  = 'No' 
    emptyFormData.listedOnStockExchange  = 'No'
    emptyFormData.singaporeFI  = 'No'
    emptyFormData.nonSingaporeFI  = 'No' // check ListD for entity

  // qn7-9

    if (currentKycData?.details?.pepAssociate == 'yes' || currentKycData?.details?.pepPerson == 'yes' || currentKycData?.details?.pepFamily == 'yes' ) {
      emptyFormData.politicallyExposedPerson = 'Yes'
    } else { emptyFormData.politicallyExposedPerson = 'No' }

    if (ListB.includes(currentKycData?.details?.nationality)) { //check List B
      emptyFormData.countryCountermeasureFATF  = 'Yes'
    } else { emptyFormData.countryCountermeasureFATF = 'No' }

    if (ListC.includes(currentKycData?.details?.nationality) ) { // Check List C
      emptyFormData.countryInadequateAML_CFT  = 'Yes'
    }  else { emptyFormData.countryInadequateAML_CFT = 'No' }


    // qn 18
    emptyFormData.higherRiskSector = 'No' 

    // qn 22, 23 
    if (highRiskJurisdictions.includes(currentKycData?.details?.nationality)) {
      emptyFormData.highRiskJurisdictionFATF  = 'Yes'
    } else { emptyFormData.highRiskJurisdictionFATF = 'No' }

    if (transparencyInternational.includes(currentKycData?.details?.nationality)) {
      emptyFormData.credibleBodyIdentifiedCountry  = 'Yes'
    }  else { emptyFormData.credibleBodyIdentifiedCountry = 'No' }
 
    return emptyFormData
  } 


  else if (currentKycData?.type == 'entity') {
    console.log("entity auto checks here")
    // qn1-4
    if (currentKycData?.details?.singaporeGovernmentEntity == 'yes' ) {
      emptyFormData.singaporeGovernmentEntity  = 'Yes'
    } else { emptyFormData.singaporeGovernmentEntity = 'No' }

    if (currentKycData?.details?.listedOnStockExchange == 'yes' ) {
      emptyFormData.listedOnStockExchange  = 'Yes'
    } else { emptyFormData.listedOnStockExchange = 'No' }

    if (currentKycData?.details?.financialInstitution == 'yes' && currentKycData?.details?.incorporationCountry == 'SG') {
      emptyFormData.singaporeFI  = 'Yes'
    } else { emptyFormData.singaporeFI = 'No' }
 
    if (currentKycData?.details?.financialInstitution == 'yes' && ListD.includes(currentKycData?.details?.incorporationCountry)) { //check List D
      emptyFormData.nonSingaporeFI  = 'Yes'
    } else { emptyFormData.nonSingaporeFI = 'No' }

    // qn7
    emptyFormData.politicallyExposedPerson = 'No' 
    currentKycData?.details?.connectedParty.forEach( party => { // .map produces a new array .forEach modify in place
      if (party?.pepAssociate == 'yes' || party?.pepPerson == 'yes' || party?.pepFamily == 'yes' ) {
        emptyFormData.politicallyExposedPerson = 'Yes'
      }   
    })

    // qn8
    emptyFormData.countryCountermeasureFATF = 'No' 
    if (ListB.includes(currentKycData?.details?.incorporationCountry)) {
       emptyFormData.countryCountermeasureFATF = 'Yes' 
    }
    currentKycData?.details?.connectedParty.forEach( party => {  
      if ( ListB.includes(party.addressCountry) || ListB.includes(party.nationality) ) {
        emptyFormData.countryCountermeasureFATF = 'Yes'
      }   
    })

    // qn9
    emptyFormData.countryInadequateAML_CFT = 'No' 
    if (ListC.includes(currentKycData?.details?.incorporationCountry)) {
       emptyFormData.countryInadequateAML_CFT = 'Yes' 
    }
    currentKycData?.details?.connectedParty.forEach( party => {  
      if ( ListC.includes(party.addressCountry) || ListC.includes(party.nationality) ) {
        emptyFormData.countryInadequateAML_CFT = 'Yes'
      }   
    })


    // qn 18 // natureOfBusiness dropDown of industry
    emptyFormData.higherRiskSector = 'No' 
    if (currentKycData?.details?.natureOfBusiness?.split(" ")[0] == 'Yes' ) {   // ?.split() apparantly can use it to stop methods too, not just properties
      emptyFormData.higherRiskSector = 'Yes' 
    }
 
    // q22 
    emptyFormData.highRiskJurisdictionFATF = 'No' 
    if (highRiskJurisdictions.includes(currentKycData?.details?.incorporationCountry)) {
       emptyFormData.highRiskJurisdictionFATF = 'Yes' 
    }
    currentKycData?.details?.connectedParty.forEach( party => {  
      if ( highRiskJurisdictions.includes(party.addressCountry) || highRiskJurisdictions.includes(party.nationality) ) {
        emptyFormData.highRiskJurisdictionFATF = 'Yes'
      }   
    })

 
    // q23
    emptyFormData.credibleBodyIdentifiedCountry = 'No' 
    if (transparencyInternational.includes(currentKycData?.details?.incorporationCountry)) {
       emptyFormData.credibleBodyIdentifiedCountry = 'Yes' 
    }
    currentKycData?.details?.connectedParty.forEach( party => {  
      if ( transparencyInternational.includes(party.addressCountry) || transparencyInternational.includes(party.nationality) ) {
        emptyFormData.credibleBodyIdentifiedCountry = 'Yes'
      }   
    })
 

    return emptyFormData
  }
}