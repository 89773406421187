import React from 'react'
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const FormSteps = ({ currentStep, setCurrentStep, totalSteps = 5 }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const currentPage = queryParams.get('page'); 
 

  return (
    <div className="flex justify-between items-start w-full max-w-3xl mx-auto mt-4">
      <div className="flex flex-col items-center">
      <Link to={window.location.href.replace(/page=\d+/, 'page=1')} replace={window.location.href.replace(/page=\d+/, 'page=1')} onClick={() => window.scrollTo(0, 0)} className={`${currentPage == 1 ? 'bg-gray-400' : 'bg-gray-300' } w-24 h-10 p-2.5 rounded-full flex justify-center items-center font-bold text-lg transition-all duration-300 ease-in-out mb-0.5 cursor-pointer text-gray-600 hover:bg-gray-400`}>
          1
          <span className="p-1 text-xs text-center font-medium max-w-[100px] text-gray-600">
            Client Details
          </span>
      </Link> 
      
      </div>

      <div className="flex-grow h-0.5 mt-6 mx-2 bg-gray-300 transition-all duration-300 ease-in-out"></div>

      <div className="flex flex-col items-center">
      <Link to={window.location.href.replace(/page=\d+/, 'page=2')} replace={window.location.href.replace(/page=\d+/, 'page=2')} onClick={() => window.scrollTo(0, 0)} className={`${currentPage == 2 ? 'bg-gray-400' : 'bg-gray-300' } w-24 h-10 p-2.5 rounded-full flex justify-center items-center font-bold text-lg transition-all duration-300 ease-in-out mb-0.5 cursor-pointer text-gray-600 hover:bg-gray-400`}>
          2
          <span className="p-1 text-xs text-center font-medium max-w-[100px] text-gray-600">
            Risk Assessment
          </span>
      </Link> 
      </div>

      <div className="flex-grow h-0.5 mt-6 mx-2 bg-gray-300 transition-all duration-300 ease-in-out"></div>

      <div className="flex flex-col items-center">
      <Link to={window.location.href.replace(/page=\d+/, 'page=3')} replace={window.location.href.replace(/page=\d+/, 'page=3')} onClick={() => window.scrollTo(0, 0)} className={`${currentPage == 3 ? 'bg-gray-400' : 'bg-gray-300' } w-24 h-10 p-2.5 rounded-full flex justify-center items-center font-bold text-lg transition-all duration-300 ease-in-out mb-0.5 cursor-pointer text-gray-600 hover:bg-gray-400`}>
          3
          <span className="p-1 text-xs text-center font-medium max-w-[100px] text-gray-600">
            Ongoing Monitoring
          </span>
      </Link> 
      </div>
    </div>
  );
};

export default FormSteps