import React, { useState } from 'react';
import { createCompany, updateCompany, createNotification } from "../../API/Api"
import { toast } from 'react-toastify';


const AddEditCompanyModal = ({handleCloseModal, notifyMissing, notify, action, setLoading, companyDetails}) => {
  const [submitted, setSubmitted] = useState(false);
  const [newCompanyData, setNewCompanyData] = useState({
    companyName: companyDetails.companyName || '',
    adminName : companyDetails.adminName || '',
    adminEmail : companyDetails.adminEmail || '',
    status : 'invited',
    createdAt : companyDetails.createdAt
  }); 
 
 
  const handleSubmit = async (e) => {
    // setSubmitted(true)
    e.preventDefault(); 

    // for (const key in newCompanyData) {
    //   if (newCompanyData[key].trim() === '') { 
    //     notifyMissing() 
    //     return; // Prevent form submission
    //   }}

    // createNotification({
    //     companyId: userCompanyId,
    //     type: 'KYC',
    //     text: `A new person, ${newCompanyData.username}, has been added`,
    //     date: new Date().toISOString(),
    // })




        if (action === 'add') {
          const createdCompanyData = {
            ...newCompanyData,
            createdAt: new Date().toISOString(),
            }
          await createCompany(createdCompanyData) 
          // await createCompany(createdCompanyData, token) //include token
      
        } else {
          const createdCompanyData = {
            ...newCompanyData,
            }
          await updateCompany(createdCompanyData)
          // await updateCompany(createdCompanyData, token)
        }


    setLoading(true) // have to trigger re-fetch from DB cuz need the added objectId
    toast.success("Invitation sent!")
    handleCloseModal()
    }
 
  const handleInputChange = (e) => {
    setNewCompanyData(prevState => ({
        ...prevState,
        [e.target.name] : e.target.value // name refer to 'input name' property.
    }))
  }


  return (
 <div className="sm:w-[60vw] md:w-[45vw] lg:w-[40vw] xl:w-[30vw] p-5">  {/* //-> viewport for different breakpoints */}
      <form onSubmit={handleSubmit}>
              <div>
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700"> Company Name: </label>
                  <input name="companyName" placeholder="Enter Company Name" value={newCompanyData.companyName} onChange={handleInputChange} className="border border-gray-300 rounded-md p-2 w-full" />
              </div>
              <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700 mt-3"> Admin Name: </label>
                  <input name="adminName" placeholder="Enter Admin Name"  value={newCompanyData.adminName}  className="border border-gray-300 rounded-md p-2 w-full" onChange={handleInputChange} />
              </div>
              <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700 mt-3"> Admin Email: </label>
                  <input name="adminEmail" placeholder="Enter Admin Email"  value={newCompanyData.email}  className="border border-gray-300 rounded-md p-2 w-full" onChange={handleInputChange} />
              </div>

          <button type="submit" disabled={submitted}  className={`bg-green-500 mt-3 text-white font-semibold py-2 px-4 rounded-md ${submitted ? 'cursor-not-allowed opacity-50' : 'hover:bg-green-600'}`}>
                {action} 
          </button>
      </form>
    </div>
  );
};

export default AddEditCompanyModal;


   