import React, {useState, useEffect} from 'react';
import { loginUser } from '../../API/Api';
import { jwtDecode } from "jwt-decode";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import iCompass_icon from '../../images/iCompass_icon.png'
// import { AuthProvider } from '../../AuthContext';
// import Routes from './Routes';

function Login({setIsLoggedIn}) {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({ 
    email: '', password: '' 
  })
  const [forgetPassword, setForgetPassword] = useState(false)
  const [error, setError] = useState(false)
  
  const currentLocation = useLocation();



  const handleSignIn = async (e) => {
    e.preventDefault()
    setError(false)
    let resultUser
    try { 
      // resultUser = await loginUser({email: 'admin@test.com', password: 'Phuatimothy@6' })
      resultUser = await loginUser(formData)
    } catch(e) { 
      console.log("error logging in") 
    }

    if (!resultUser?.data?.status === true) {
      setFormData({ ...formData, password : ''}) 
      setError(true)
      return
    }

    const accessToken = resultUser.data.accessToken
    const decodedToken = jwtDecode(accessToken) 

    localStorage.setItem('accessToken', accessToken);  // some how resultUser.data?.accessToken corrupts the token?, the question mark
    localStorage.setItem('refreshToken', resultUser.data.refreshToken);  // some how resultUser.data?.accessToken corrupts the token?, the question mark
    setIsLoggedIn( localStorage.getItem('accessToken') ) 

    if (decodedToken.role == 'superAdmin') {
      window.location.href = '/super-admin'
    } else {
      // window.location.href = '/kyc-aml/customer-list'
      // window.location.href = '/'
      navigate('/home')
    }
  }


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const newFormData = {  // instead of setState, prevData => {...prevData, new: newValue}
      ...formData,         // do this, to ensure is kept up to date
      [name] : value  
    }
    // console.log(newFormData)
    setFormData(newFormData) 
  }


  return (
    <div className='relative w-full h-screen bg-zinc-800/90'>

    {!forgetPassword && (
      <div className='flex justify-center items-center h-full'>
          <form onSubmit={handleSignIn} className='max-w-[400px] w-full mx-auto bg-white p-8'>
              <h2 className='text-4xl font-bold text-center py-4'>
                <img src={iCompass_icon} className='m-auto w-3/4'/> 
              </h2>

              <div className='flex flex-col mb-4 '>
                  <label className='border p-1'>*Note, if logging in for first time after 1st October, please reset your password*</label>
              </div>

              <div className='flex flex-col mb-4'>
                  <label>Username</label>
                  <input onChange={handleInputChange}  name='email' className='border relative bg-gray-100 p-2' type="text" placeholder='Email address'   value={formData.email}/>
              </div>
              <div className='flex flex-col '>
                  <label>Password</label>
                  <input onChange={handleInputChange} name='password' className='border relative bg-gray-100 p-2' type="password" placeholder='Password' value={formData.password} />
              </div>
              <input type='submit' value='Sign In' className='cursor-pointer w-full py-3 mt-8 bg-green-600 hover:bg-green-500 relative text-white'/>
          
              <Link to='/reset-password' replace={currentLocation.pathname === '/reset-password '}>
                <button className='w-full text-sm cursor-pointer p-2 mt-5 bg-blue-600 hover:bg-blue-500 relative text-white'>Forget Password?</button>
              </Link>

              {error && (
                <p className="max-w-sm rounded overflow-hidden p-4 bg-red-200 text-gray-700 my-4 text-center"> 
                  User not found with this email <br/> or Incorrect Password
                </p> 
              )}
          </form>
      </div>
      )}
    </div>
  );
}

export default Login;
