import React, {useState, useEffect, useMemo} from "react";
import { BrowserRouter, Routes, Route, Outlet, Navigate } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import { QueryClient, QueryClientProvider, } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { getNewAccessAndRefreshToken } from "./API/Api";
// import { TokenProvider } from "./context/TokenContext";

import TopBar from "./components/shared/TopBar";
import SideBar from "./components/shared/SideBar";
import { RightContainer } from "./components/shared/utils";

import Login from "./components/login/Login";
import ResetPassword from "./components/login/ResetPassword";
import PageNotFound from './PageNotFound'
import SuperAdminCompaniesTable from './components/super_admin/SuperAdminCompaniesTable'
import AdminTable from './components/admin/AdminTable'
import Profile from "./components/shared/profile/Profile";
import ChangeLog from "./components/shared/profile/ChangeLog";

import _KycAmlDashboard from "./components/kyc_aml_portal/dashboard/_KycAmlDashboard";
import _KycAmlCustomerListTable from "./components/kyc_aml_portal/customer_list/_KycAmlCustomerListTable";
import _KycAmlArchivedCustomerTable from "./components/kyc_aml_portal/customer_list/_KycAmlArchivedCustomerTable";
import __KycAmlForm from "./components/kyc_aml_portal/form/__KycAmlForm";

import _FnPDashboard from "./components/fit_and_proper_declaration/dashboard/_FnPDashboard";
import _FnPReportingTable from "./components/fit_and_proper_declaration/reporting_table/_FnPReportingTable";
import _FnPArchivedReportingTable from "./components/fit_and_proper_declaration/reporting_table/_FnPArchivedReportingTable";
import _FnPForm from "./components/fit_and_proper_declaration/form/_FnPForm";

import _ComplianceDashboard from "./components/compliance_monitoring_program/dashboard/_ComplianceDashboard";
import __ComplianceTable from "./components/compliance_monitoring_program/__ComplianceTable";
import _ComplianceFormArchivedTable from "./components/compliance_monitoring_program/form_user_table/_ComplianceFormArchivedTable";
import _CreateUpdateComplianceTopicForm from "./components/compliance_monitoring_program/topics_monitoring_table/_CreateUpdateComplianceTopicForm";
import _ComplianceFormResponse from "./components/compliance_monitoring_program/form_user_table/_ComplianceFormResponse";
import { DateTime } from "luxon";

const queryClient = new QueryClient()

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [open, setOpen] = useState(true);

  const Container = useMemo(() => {
    const ContainerComponent = open 
      ? RightContainer 
      : React.memo(({ children, ...props }) => (
          <div  {...props}  className=" ml-20 flex-grow  px-9 pt-4 max-w-screen-2xl h-screen" >
            {/* // className="flex-1 ml-20 flex-grow  px-9 pt-4 overflow-y-auto overflow-x-auto max-w-screen-2xl h-screen" why does it trigger another scrollbar, and why is itboth has to be present */}
            {children}
          </div>
        ));
  
    return React.memo(({ children }) => (
      <ContainerComponent>{children}</ContainerComponent>
    ));
  }, [open]);

 
  useEffect(() => { 
    setIsLoggedIn( localStorage.getItem('accessToken') ) // ? check if has assigned role, else its a formToken and deny it

    const refreshAccessToken = async () => {
      console.log('refreshing token')
      try {
        const response = await getNewAccessAndRefreshToken( localStorage.getItem('refreshToken') )
        const {accessToken, refreshToken } = response.data;  

        localStorage.setItem('accessToken', accessToken); 
        localStorage.setItem('refreshToken', refreshToken);
      } catch (error) {
        console.error('Error refreshing access token'); // or only log the generic response, not the entire err trace stack, should be an external logging service 
      }
    }; 
    let intervalId
    if ( localStorage.getItem('accessToken') ) { 
      refreshAccessToken();
      intervalId = setInterval(refreshAccessToken,  880000  ); // 14.40 minutes =  880000  milliseconds
    } 
    return () => clearInterval(intervalId);  // Clean up the interval on component unmount
  }, [isLoggedIn]);
// }, []);

  return (
    <QueryClientProvider client={queryClient}>
      {/* <TokenProvider> */}
        <BrowserRouter>
        {isLoggedIn ? (
          <>
            <TopBar />
            <SideBar setOpen={setOpen} open={open}/>
            <ToastContainer pauseOnHover={false} pauseOnFocusLoss={false} limit={3} autoClose={2500}/>
    
            <Container>
              <Routes>
                  <Route path="/" element={<Home/>}  />
                  <Route path="/home" element={<Home/>}  />
                  <Route path="/reset-password" element={<ResetPassword/>} />
                  <Route path="/profile" element={<Profile/>}/>
                  <Route path="/change-log" element={<ChangeLog />} />

                  <Route element={<AdminProtectedRoutes />}>
                    <Route path="/admin" element={<AdminTable />}/>
                    <Route path="/super-admin" element={<SuperAdminCompaniesTable />} />
                  </Route>

                  <Route element={<KycProtectedRoutes />}>
                    <Route path="/kyc-aml" element={<_KycAmlDashboard />} />
                    <Route path="/kyc-aml/customer-list" element={<_KycAmlCustomerListTable />} />
                    <Route path="/kyc-aml/archived-customer-list" element={<_KycAmlArchivedCustomerTable />} />
                    <Route path="/kyc-aml/form" element={<__KycAmlForm />} />
                    <Route path="/form/kyc-aml" element={<__KycAmlForm />} /> {/*  To access this regardless log in or not */}
                  </Route>


                  <Route element={<CmpProtectedRoutes />}>
                    <Route path="/compliance-monitoring" element={<_ComplianceDashboard />} />
                    <Route path="/compliance-monitoring/monitoring" element={<__ComplianceTable />} />
                    <Route path="/compliance-monitoring/archived-monitoring" element={<_ComplianceFormArchivedTable />} />
                    <Route path="/compliance-monitoring/topic-form" element={<_CreateUpdateComplianceTopicForm />} />
                    <Route path="/compliance-monitoring/user-form" element={<_ComplianceFormResponse />} />
                  </Route>


                  <Route path="/fit-and-proper" element={<_FnPDashboard />} />
                  <Route path="/fit-and-proper/reporting-status" element={<_FnPReportingTable />} />
                  <Route path="/fit-and-proper/archived-reporting-status" element={<_FnPArchivedReportingTable />} />
                  <Route path="/fit-and-proper/form" element={<_FnPForm />} />
                  
                  <Route path="*" element={<PageNotFound />} />
              </Routes>
            </Container>
          </> 
        ) : (
          <Routes>
            <Route path="/form/kyc-aml" element={<__KycAmlForm />} />
            <Route path="/" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
            <Route path="/reset-password" element={<ResetPassword/>} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        )}
      </BrowserRouter>
    {/* </TokenProvider> */}
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);
}

export default App;



const CmpProtectedRoutes = () => {
  const decodedToken = jwtDecode(localStorage.getItem('accessToken')) 
  return decodedToken.subscriptions.cmp ? <Outlet/> : <Navigate to="/profile" />
}

const KycProtectedRoutes = () => {
  const decodedToken = jwtDecode(localStorage.getItem('accessToken')) 
  return decodedToken.subscriptions.kyc_aml ? <Outlet/> : <Navigate to="/profile" />
}

// const FnpProtectedRoutes = () => {
//   const decodedToken = jwtDecode(localStorage.getItem('accessToken')) 
//   return decodedToken.subscriptions.kyc_aml ? <Outlet/> : <Navigate to="/profile" />
// }

const AdminProtectedRoutes = () => {
  const decodedToken = jwtDecode(localStorage.getItem('accessToken')) 
  return decodedToken.role == 'admin' ? <Outlet/> : <Navigate to="/profile" />
}


const Home = () => {
  return <div> ← Navigate app with SideBar </div>
}
