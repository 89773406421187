import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom'; 
import { BsBell, BsPerson, BsBoxArrowRight  } from "react-icons/bs";
import { jwtDecode } from 'jwt-decode';
import { getCompanyDetails, getAllNotifications, getUserById, updateUser } from '../../API/Api';
import { DateTime, Duration } from 'luxon';
import { LuBellDot } from "react-icons/lu";
import BasicMenu from './BasicMenu'
import { Link } from 'react-router-dom';

const TopBar = () => {
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);
  const [allNotifications, setAllNotifications] = useState([]);
  const [notificationChecked, setNotificationChecked] = useState(DateTime.now().toFormat('dd-MM-yyyy HH:mm:ss'));
  const [image, setImage] = useState();
  let decodedToken
  const token = localStorage.getItem('accessToken')
  if (token) { decodedToken = jwtDecode(token) }


// now change this to react-query

  useEffect(() => {
    const fetchCompanyLogo = async () => {
      const companyData = await getCompanyDetails('company', token)

      // insert the appropriate record in table
      setImage(companyData.data.logoUrl);
      const notifications = await getAllNotifications('', token)
      setAllNotifications(notifications.data)
      // const notificationChecked = await getUserById(decodedToken._id, token)
      // setNotificationChecked(notificationChecked.data.checkedNotification) 
    }

    if (token) {
      fetchCompanyLogo()
    }

  }, []);

  const toggleNotificationDropdown = () => {
    setNotificationOpen(!notificationOpen);
    updateUser({
      checkedNotification: DateTime.now().toFormat('dd-MM-yyyy HH:mm:ss'),
      user_id: decodedToken._id
    }, token)
    setNotificationChecked(DateTime.now().toFormat('dd-MM-yyyy HH:mm:ss'))
    setProfileOpen(false); // Close the other dropdown
  };

  const toggleProfileDropdown = () => {
    setProfileOpen(!profileOpen);
    setNotificationOpen(false); // Close the other dropdown
  };
 
  const companyName = jwtDecode(localStorage.getItem('accessToken')).companyName


  function getRelativeTimeString(timestamp) {
    const pastDateTime = DateTime.fromFormat(timestamp, 'dd-MM-yyyy HH:mm:ss'); // Parse the given timestamp
    const now = DateTime.now();
    const diff = now.diff(pastDateTime); // Calculate the difference
    
    const days = diff.as('days'); // Convert to units
    const hours = diff.as('hours');
    const minutes = diff.as('minutes');
    
    if (days >= 1) {
      return `${Math.floor(days)} day${days >= 2 ? 's' : ''} ago`;
    } else if (hours >= 1) {
      return `${Math.floor(hours)} hour${hours >= 2 ? 's' : ''} ago`;
    } else if (minutes >= 1) {
      return `${Math.floor(minutes)} minute${minutes >= 2 ? 's' : ''} ago`;
    } else {
      return 'Just now';
    }
  }

  function newNotificationAfterLastCheck() { 
    const newestNotificationTime = allNotifications[-1]
    // setNotificationChecked( DateTime.now().toFormat('dd-MM-yyyy HH:mm:ss') )
    return newestNotificationTime < notificationChecked;
  } 

  function handleLogOut() {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('stringToken')
  }


  return (
    //  <nav className="bg-green-600 p-4 z-10 ">
    <nav className="bg-green-500 z-10 p-1.5 sticky top-0 ">
      <div className="flex justify-end items-center">
        <div>
          {image ? (
              <img src={image} alt="Profile" style={{ width: '8%', height: '8%', objectFit: 'cover' }} className='bg-white float-right'/>
            ) : (
              <span className='font-serif border-y border-green-300 px-2 '> {companyName} </span>
            )}
        </div>


      <>
        {/* <div className="relative">
          <button className="text-white mx-1 px-5" onClick={toggleNotificationDropdown}>
            { newNotificationAfterLastCheck() ? <BsBell className='scale-150'/> : <LuBellDot className='scale-150 text-red-500'/>}
          </button>
          {notificationOpen && (
            <div className="absolute right-0 mt-2 w-80 bg-white rounded-lg shadow-lg h-72 overflow-y-auto">
              <p className="px-4 py-2 bg-cyan-900 sticky top-0 border-2 border-cyan-900 text-white rounded-t">Notifications</p>
              <ul> 
                {allNotifications.map((notification, index) => (
                  <li key={index} className="px-4 py-2 hover:bg-green-100 hover:rounded-lg">
                    <div className='flex justify-between'>
                      <h4 className="font-bold mb-2"> {notification.type} </h4>
                      <p className='text-sm text-gray-800'> {getRelativeTimeString(notification.date)} </p>
                    </div>
                    <p> {notification.text} </p>  
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div> */}
        </>

        <BasicMenu icon={ true ? <BsBell className='text-white scale-150 mx-3'/> : <LuBellDot className='scale-150 text-red-500 mx-3'/>} >
        <div className='bottom-2 relative'>
            {/* <div className="absolute right-0 mt-2 w-80 bg-white rounded-lg shadow-lg h-72 overflow-y-auto"> */}
              <p className="px-4 py-2 bg-cyan-900 sticky top-0 border-2 border-cyan-900 text-white rounded-t">Notifications</p>
              <ul> 
                {allNotifications.map((notification, index) => (
                  <li key={index} className="px-4 py-2 hover:bg-green-100 hover:rounded-lg">
                    <div className='flex justify-between'>
                      <h4 className="font-bold mb-2"> {notification.type} </h4>
                      <p className='text-sm text-gray-800'> {getRelativeTimeString(notification.date)} </p>
                    </div>
                    <p> {notification.text} </p>  
                  </li>
                ))}
              </ul>
            {/* </div> */}
            </div>
        </BasicMenu>

        {/* <Link to='/admin' replace={currentLocation.pathname === '/admin'}> */}

          <BasicMenu icon={<BsPerson className='scale-150 text-white mr-5'/> } >
            <Link to='/profile' className="text-black px-4 py-2 hover:bg-gray-200 hover:rounded-lg flex justify-between"> User Profile <BsPerson className='scale-125 inline ml-3'/>  </Link>
              {/* <a href="/profile" className="text-black px-4 py-2 hover:bg-gray-200 hover:rounded-lg flex justify-between"> User Profile <BsPerson className='scale-125 inline ml-3'/>  </a> */}
              <a href="/" onClick={handleLogOut} className="text-black px-4 py-2 hover:bg-gray-200 hover:rounded-lg flex justify-between"> Logout <BsBoxArrowRight className='scale-125 inline'/> </a>
          </BasicMenu>

            <>
        {/* <div className="relative">
          <button className='px-5 text-white' onClick={toggleProfileDropdown}>
            <BsPerson className='scale-150'/> 
          </button>
          {profileOpen && (
            <div className="absolute right-0 mt-2 w-52 bg-white border rounded-lg shadow-lg">
                <a href="/profile" className="text-black px-4 py-2 hover:bg-gray-200 hover:rounded-lg flex justify-between"> User Profile <BsPerson className='scale-125 inline'/>  </a>
                <a href="/" onClick={()=> localStorage.removeItem('accessToken')} className="text-black px-4 py-2 hover:bg-gray-200 hover:rounded-lg flex justify-between"> Logout <BsBoxArrowRight className='scale-125 inline'/> </a>
            </div>
          )}
        </div>  */}
        </>
      </div>
    </nav>
  );
};

export default TopBar;


