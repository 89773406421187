import React, {useState, useEffect} from 'react'
import { Card } from '../../shared/utils' 
import { getCmpForm, getAllUsers } from '../../../API/Api'
import FormQuestions from './FormQuestions'
import { useLocation } from 'react-router-dom'

const _ComplianceFormResponse = () => {
  const token = localStorage.getItem('accessToken')
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const formId = queryParams.get('formId');

  const [subTopicsArray, setSupTopicsArray ] = useState([]) 
  const [cmpForm, setCmpForm] = useState({})  
  const [allUsers, setAllUsers] = useState({})  

  const [fetchCounter, setFetchCounter ] = useState(0)  

  useEffect( () => {
    const fetchData = async () => { 
      const cmpFormData = await getCmpForm(formId,token)
      const allUsersData = await getAllUsers(token)
      setAllUsers(allUsersData.data) 
      setCmpForm(cmpFormData.data) 
 
      if (fetchCounter == 0) {
        setSupTopicsArray(cmpFormData.data.subTopics) 
      }
    }
    fetchData()
    },  
  [fetchCounter]);
 

  if (subTopicsArray.length == 0) { 
    return <div> Loading... </div>
  }

  return (
    <div className='max-w-6xl'>
      <Card>
        {/* <strong className='underline'>Topic:</strong>  */}
        {/* <span className='mr-3'>   {cmpForm?.topicName}</span> */}
        {/* <strong className='underline'>Form:</strong> */}
        <span className='mr-3'>    {cmpForm?.formName}</span> 
        <br/>
        {/* <strong className='underline mr-2'>Cycle Start Date:</strong> */}
        {/* <span className='mr-3'>{cmpForm?.startDate}</span> */}
        <strong className='underline mr-2'>From:</strong>
        <span className='mr-3'>{cmpForm?.startDate} {cmpForm?.startDate && 'to'} {cmpForm?.expiryDate}</span>
      </Card>

      {/* loop all subtopics. pass in the subTopic within component  */}
      <div>
        {subTopicsArray.map( (subTopic, subTopicIndex) => (
          <Card className={'mb-12'}>
            <strong className='border underline block bg-green-300 p-4 -m-0.5 rounded-md'> {subTopic.subTopicName}: </strong>
            {/* <strong className='border underline block bg-green-300 p-4 -m-0.5 rounded-md'> SubTopic {subTopicIndex + 1}, {subTopic.subTopicName}: </strong> */}
            {subTopic.questions.map( (question, questionIndex) => (
              <FormQuestions question={question} subTopicIndex={subTopicIndex} questionIndex={questionIndex} cmpForm={cmpForm} setCmpForm={setCmpForm} setFetchCounter={setFetchCounter} allUsers={allUsers}/>
            ))}
          </Card>
          ))
        }
      </div>

      <br/> <br/>  
    </div>
  )
}

export default _ComplianceFormResponse