import React from 'react'
import { Card } from '../utils'

const ChangeLog = () => {
  return (
    <>
    <Card>
        <strong className='text-xl mb-2'> ChangeLog </strong>
    </Card>
    
    <Card>
        {/* <ul>
            <strong className='text-lg mb-2'> Oct 2024 </strong>
            <li> - KYC, </li>
            <li> - KYC, </li>
            <li> - KYC, </li>
        </ul> <br/> */}
        <ul>
            <strong className='text-lg mb-2'> Oct 2024 </strong>
            <li> - KYC, Add mandatory fields</li>
            <li> - KYC, Add 'Account manager' field</li>
            <li> - KYC, Add source of wealth/fund definitions</li>
            <li> - KYC, Add summary report & individual report download</li>
        </ul> <br/>
        <ul>
            <strong className='text-lg mb-2'> Sept 2024 </strong> 
            <li> - Password need 8 characters long with special characters  </li>
            <li> - Auto log out if inactive for 2 hours</li>
            <li> - CMP, Add multi-select emails to notify manager</li>
            <li> - CMP, Add creator email for Topic </li>
            <li> - CMP, Remove columns for Pending Action/review/reviewed in forms (info to be displayed in dashboard in future) </li>
        </ul> 
        <br/>
        <ul>
            <strong className='text-lg mb-2'> August 2024 </strong>
            <li> - Add “Singapore” as default selected nationality option </li>
            <li> - Add remove uploaded files feature  </li>
            <li> - Add sidebar feedback link </li>
            <li> - Fix email not sent </li>
            <li> - Add "Viewer" role </li>
            <li> - Add notification in top menu  </li>
        </ul> <br/>
        

    </Card>
    </>
  )
}

export default ChangeLog


 
