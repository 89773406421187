import React, { useState, useEffect } from 'react';
import { BsChevronDown, BsDownload, BsTrash3, BsCopy } from 'react-icons/bs';
import { RightContainer, Row, Badge } from "../../shared/utils"
import { getkycAll, deletekyc, frontEndBaseURL, getkycPDF } from '../../../API/Api'
import { Link, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { DateTime } from 'luxon';
import { jwtDecode } from 'jwt-decode';
import Tooltip from '@mui/material/Tooltip'; 
import 'react-toastify/dist/ReactToastify.css';


const _KycAmlArchivedCustomerTable = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState(null);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortDirection, setSortDirection] = useState('desc');
  const [collapsed, setCollapsed] = useState(false); // State for collapsing the table
  const currentLocation = useLocation();
  const token = localStorage.getItem('accessToken')
  const decodedToken =  jwtDecode(token)

useEffect(() => {
      const companyId = jwtDecode(localStorage.getItem('accessToken')).companyId
      getkycAll(companyId, token).then((response) => {
        const archivedKycData = []
        response.data.forEach(item => {
          if (item.archived) {
            archivedKycData.push({
              id: item._id,
              name: item.name,
              email: item.email,
              assignedDate: item.submitedDate,
              lastActivity: item.updatedDate,
              status: item.formStatus
            })
          }
        });
        setUsers(archivedKycData)
        setLoading(false);
      })
        .catch((error) => {
              console.error('Error fetching KYC data:', error);
          });
  }, [loading]);

  const copyFormLink = async (userId) => {
    const linkToCopy = frontEndBaseURL + '/form/kyc-aml?id=' + userId;
    await navigator.clipboard.writeText(linkToCopy)
    notifyCopy()
  }

  const notifyCopy = () => toast.success("External Form Link Copied");
  const notifyArchived = () => toast.info("User successfully deleted");


  const convertToSingaporeTime = (dateTime) => {
    const utcTimestamp = new Date(dateTime);
    const singaporeTime = new Date(utcTimestamp.getTime());  
    // const singaporeTime = new Date(utcTimestamp.getTime() + (8 * 60 * 60 * 1000)); // Adding 8 hours (UTC+8)

    // Format the date and time in "26th Feb 2024, 11:41" format
    const options = { 
      year: 'numeric', 
      month: 'short', 
      day: '2-digit', 
      hour: '2-digit', 
      minute: '2-digit', 
      hour12: false 
    };

    const formatter = new Intl.DateTimeFormat('en-SG', options);
    return formatter.format(singaporeTime);
  }

  const handleKycPDF = async(userId, userName) => {
    const response = await getkycPDF(userId); // Update the URL with your backend API endpoint
    // const url = window.URL.createObjectURL(blob);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;

    const filename = `${userName}_Details_${DateTime.now().setZone('Asia/Singapore').toFormat("dd-MM-yyyy, HH:mm")}.pdf`;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
  }

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortDirection('desc');
    }
  };

  const handleDelete = async (userId) => {
    await deletekyc(userId, token)
    setLoading(true) // have to trigger re-fetch from DB cuz need the added objectId
    notifyArchived()
  }


  const sortedUsers = users.sort((a, b) => {
    if (sortBy === 'lastActivity') {
      if (sortDirection === 'asc') {
        return new Date(a.lastActivity) - new Date(b.lastActivity);
      } else {
        return new Date(b.lastActivity) - new Date(a.lastActivity);
      }
    }
    return 0;
  });

  const filteredUsers = sortedUsers.filter((user) =>
    user.name.toLowerCase().includes(searchTerm)
  );

  const handleCollapseToggle = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div >
      {/* <ToastContainer pauseOnHover={false} pauseOnFocusLoss={false} /> */}
      <Row>
        <div className="w-full flex justify-between items-center p-4 bg-white shadow">
          <strong>KYC Archive</strong>
          <input
            type="text"
            placeholder="Search Name"
            value={searchTerm}
            onChange={handleSearchChange}
            className="p-2 border border-gray-300 rounded-lg mr-2 w-1/3"
          />
          <Link to='/kyc-aml/customer-list' replace={currentLocation.pathname === '/'}>
            <button className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg">View Active</button>
          </Link>

        </div>
      </Row>
      {!collapsed && (
        <div>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <div>
            <table className="w-full border-collapse border border-gray-300 shadow table-auto">
              <thead>
                <tr className="bg-gray-300 text-left">
                  <th className="p-2">Name</th>
                  <th className="p-2">Email</th>
                  <th className="p-2">Assigned Date</th>
                  {/* <th className="p-2 cursor-pointer flex" onClick={() => handleSort('lastActivity')}> Last Activity  <span className='pl-2 pt-1'> <BsChevronDown  /> </span> <BsChevronDown className={`${Menu.isOpen && 'rotate-180'}`} /> </th> */}
                  <th className="p-2 cursor-pointer" onClick={() => handleSort('lastActivity')}> <span className='pl-2 pt-1 flex text-center'> Year of Declaration <BsChevronDown  className='ml-1 mt-1.5 flex' /> </span> {/* <BsChevronDown className={`${Menu.isOpen && 'rotate-180'}`} /> */} </th>
                  <th className="p-2 cursor-pointer" onClick={() => handleSort('lastActivity')}> <span className='pl-2 pt-1 flex'> Review Date <BsChevronDown  className='ml-1 mt-1.5 flex' /> </span> {/* <BsChevronDown className={`${Menu.isOpen && 'rotate-180'}`} /> */} </th>
                  <th className="p-2 cursor-pointer" onClick={() => handleSort('lastActivity')}> <span className='pl-2 pt-1 flex'> Approval Date <BsChevronDown  className='ml-1 mt-1.5 flex' /> </span> {/* <BsChevronDown className={`${Menu.isOpen && 'rotate-180'}`} /> */} </th>
                  <th className="p-2">Status</th>
                  <th className="p-2">Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredUsers.map((user, index) => (
                  <tr key={user.id} className={index % 2 === 0 ? 'bg-green-50 hover:bg-green-100' : 'bg-white hover:bg-green-100'}>
                    <td className="p-2 w-1/6">{user.name}</td>
                    <td className="p-2 w-1/6">{user.email}</td>
                    <td className="p-2 w-1/6">{convertToSingaporeTime(user.assignedDate)}</td>
                    <td className="p-2 w-1/12 text-center">{user.assignedDate.substring(0,4)}</td>
                    <td className="p-2 w-1/6">{convertToSingaporeTime(user.assignedDate)}</td>
                    <td className="p-2 w-1/6">{convertToSingaporeTime(user.assignedDate)}</td>
                    <td className="p-2 w-1/12"> <label style={{ fontSize: 12 }} className="bg-purple-500 text-white rounded-full px-2 py-1 text-xs font-semibold"> Archived </label> </td>
                    {/* <td className="p-2 w-1/12"> <label style={{ fontSize: 12 }} className="bg-purple-500 text-white rounded-full px-2 py-1 text-xs font-semibold"> Archived / ((Closed))</label> </td> */}
                    <td className="p-2 w-1/12">
                      <div className="flex">
                        <Tooltip title={"Copy Form Link"} onClick={() => copyFormLink(user.id)} className='p-2.5 mx-1 rounded-full border border-stone-500 cursor-pointer hover:bg-gray-400  transition duration-500' arrow>  <BsCopy/>  </Tooltip>
                        <Tooltip title={"Download"}  onClick={() => handleKycPDF(user.id, user.name)} className='p-2.5 mx-1 rounded-full cursor-pointer hover:bg-gray-400 border border-stone-500 transition duration-500' arrow> <BsDownload /> </Tooltip> 
                        <Tooltip title={"Delete"} onClick={() => handleDelete(user.id)} className='p-2.5 mx-1 rounded-full border border-stone-500 bg-red-200 cursor-pointer hover:bg-red-400  transition duration-500' arrow> <BsTrash3/> </Tooltip>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          )}
        </div>
      )}
    </div>
  );
};

 

export default _KycAmlArchivedCustomerTable;
