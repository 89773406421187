import React, {useState, useEffect} from 'react'

const PageNotFound = ({ customMessage }) => {
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const contentTimer = setTimeout(() => {
      setShowContent(true);
    }, 1050);

    const redirectTimer = setTimeout(() => {
      window.location.href = '/'; // Redirect to login or home page
    }, 4000); // 4 seconds

    return () => {
      clearTimeout(contentTimer); // Cleanup the content timer
      clearTimeout(redirectTimer); // Cleanup the redirect timer
    };
  }, []);

  // async function sleep() {
  //   await new Promise(r => setTimeout(r, 2000));
  // }
  // sleep()

  if (!showContent) {
    return <div className=' min-h-screen w-full bg-white fixed inset-0 z-50 text-center ' >   </div>
  }

  return (
    <div className='flex items-center justify-center min-h-screen w-full bg-slate-300 fixed inset-0 z-50'>
      <div className='text-center bg-white p-8 rounded-lg shadow-lg'>
        <h1 className='text-4xl font-bold text-slate-800 mb-4'>404 - Page Not Found</h1>
        {customMessage ? 
        ( 
          <p className='text-lg text-slate-600'> {customMessage} </p> 
        ):(
          <>
            <p className='text-lg text-slate-600'>The page you are looking for doesn't exist or requires user to be logged in.</p>
            <p className='text-lg text-slate-600'>Redirecting to home page in 3 seconds...</p>
          </>
        )
      }
        <br/>
        {/* <p className='text-lg text-slate-600'>Redirecting you to home page in a few seconds...</p> */}
        <a href='/' className='text-2xl text-blue-600'> Click here to return home </a>
      </div>
    </div>
  );
}

export default PageNotFound