import React, { useState } from 'react';
import { downloadKYCTemplate, createkyc, createNotification } from "../../../API/Api"
import readXlsxFile from 'read-excel-file'
import FormStatusEnum from '../../../data/FormStatusEnum';
import { jwtDecode } from "jwt-decode";
import { DateTime } from 'luxon';


const AddUserModalContent = ({handleCloseModal, notifyMissing, notify, setLoading}) => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [newUserData, setNewUserData] = useState({
    username: '',
    email : '',
    formType : 'individual',

    // updatedAt: new Date(),
    // submitedDate: new Date(),
    // status: "pendingcustomer",
  });
 //TODO, confirm the data to recieve and save at creation
   const token = localStorage.getItem('accessToken')
  const decodedToken =  jwtDecode(token)

  const getKYCTemplate = async () => {
    let response = await downloadKYCTemplate();

    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob); // Create a temporary URL for the blob
    const link = document.createElement('a'); // Create a link element to trigger the file download
    link.href = url;
    link.download = 'kyc_template.xlsx'; // Set the desired file name
    link.click();

    // Clean up the URL object
    window.URL.revokeObjectURL(url);
}


  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) { setUploadedFile(file) }
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); 
    setSubmitted(true)
    
    const userCompanyId = jwtDecode(localStorage.getItem('accessToken')).companyId
    // TODO, use xlsx or some other popular libary compare npm downloads trend instead
    if (uploadedFile) {
        try{
            const fileData = await readXlsxFile(uploadedFile)
            fileData.slice(1).map(async (row) => {
                const userData = {
                    name: row[0] || '',
                    email: row[1] || '',
                    type: row[2], //TODO, if row2 empty, do not accept
                    formStatus: FormStatusEnum.PENDING_CLIENT_DETAILS_1,
                    archived: false,
                    companyId: userCompanyId,
                    updatedDate: new Date().toISOString(),
                    submitedDate: new Date().toISOString(),
                    maxPage: 1
                }
                await createkyc(userData, token)
            })
            setLoading(true) // have to trigger re-fetch from DB cuz need the added objectId

            await createNotification({
                type: "KYC",
                text: `${fileData.slice(1).length} Persons has been added`,
                date: DateTime.now().toFormat('dd-MM-yyyy HH:mm:ss')
            }, token)
            notify()
        } catch(err) {
            console.error('An error occurred')
            // notify("Something went wrong")
            }
          } else { // create indidivual

            for (const key in newUserData) {
              if (newUserData[key].trim() === '') { 
                notifyMissing() 
                setSubmitted(false)
                return; // Prevent form submission
              }}
          
            const userData = {
                name: newUserData.username,
                email: newUserData.email,
                type: newUserData.formType,
                updatedDate: new Date().toISOString(),
                submitedDate: new Date().toISOString(),
                archived: false,
                formStatus: FormStatusEnum.PENDING_CLIENT_DETAILS_1,
                companyId: userCompanyId,
                maxPage: 1,
              }
            await createkyc(userData, token)
            createNotification({
                type: 'KYC',
                text: `A new person, ${newUserData.username}, has been added`,
                date: DateTime.now().toFormat('dd-MM-yyyy HH:mm:ss')
            }, token)

            setLoading(true) // have to trigger re-fetch from DB cuz need the added objectId
            notify()  
    }
    handleCloseModal()
  }
 
  const handleRemoveFile = () => { setUploadedFile(null) };


  const handleInputChange = (e) => {
    setNewUserData(prevState => ({
        ...prevState,
        [e.target.name] : e.target.value // name refer to 'input name' property.
    }))
  }


  return (
    <div className="w-full max-w-md mx-auto bg-white rounded-md shadow-lg p-4">
      <form onSubmit={handleSubmit}>
          <div className="mb-4 flex">
              <div className="w-1/2 ">
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1"> Name: </label>
                  <input type="text" id="name" name="username" placeholder="Enter Name"
                         className="border border-gray-300 rounded-md p-2 w-full" onChange={handleInputChange}
                         value={newUserData.username}/>
              </div>
              <div className="w-1/2 mx-6">
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1"> Email: </label>
                  <input type="email" id="email" name="email" placeholder="Enter Email"
                         className="border border-gray-300 rounded-md p-2 w-full" onChange={handleInputChange}
                         value={newUserData.email}/>
              </div>

          </div>

          <div className="mb-4">
              <label htmlFor="formType" className="block text-sm font-medium text-gray-700 mb-1"> Form Type: </label>
          <select id="formType" name="formType" className="border border-gray-300 rounded-md p-2 w-full" onChange={handleInputChange} value={newUserData.formType}>
            <option value="individual">Individual Form</option>
            <option value="entity">Entity Form</option>
          </select>
        </div>

        <div className="mb-4">
          <label htmlFor="fileInput" className="sr-only">Upload Excel Sheet</label>
          <input type="file" id="fileInput" name="fileInput" accept=".xlsx,.xls" onChange={handleFileChange} className="border border-gray-300 rounded-md p-2 w-full hidden"/>
        </div>


        {!uploadedFile ? ( <br/>)  :
          <div className="text-gray-700 mb-2">
            Uploaded File:
            <p> {uploadedFile.name}  <span className='cursor-pointer' onClick={handleRemoveFile}> ❌ </span> </p>
          </div>}

        <div className="flex items-center justify-between">
          <label htmlFor="fileInput" className="bg-blue-500 text-white font-semibold py-2 px-4 rounded-md cursor-pointer"> Upload Excel  </label>
          <span className="text-gray-500 cursor-pointer hover:text-green-600" onClick={getKYCTemplate}>Download Template</span>
          <button type="submit" disabled={submitted}  className={`bg-green-500 text-white font-semibold py-2 px-4 rounded-md ${submitted ? 'cursor-not-allowed opacity-50' : 'hover:bg-green-600'}`}>
            Submit 
          </button>
          {/* look into why using <form> </form> in react has its unique uses. */}
        </div>
      </form>
    </div>
  );
};

export default AddUserModalContent;
