import React, {useState, useEffect} from 'react'
import { Card, Row } from '../../shared/utils'
import QuestionCard from './QuestionCard'
import DeclarationCard from './DeclarationCard'

// useEffect to fetch data
// save to backend
// signature (ask if still needed)

const _FnPForm = () => {
  const [FnPDeclarationData, setFnPDeclarationData] = useState({   // ^ this isnt a good idea if gonna have multiple forms. baest to name it 
      honestyIntegrityReputation : {
        refusedRightForBusiness: '', prohibitionOrderMAS: '', censuredMAS: '', complaintSubjectMAS: '',
        disciplinaryCriminalProceedings: {}, convictedOffence: {}, judgmentCivilProceedings: {}, civilLiabilityFraud: {},
        civilPenaltyEnforcement: {}, contravenedLaws: {}, investigationsSubject: {}, refusedFidelityBond: {},
        unwillingnessComplyRegulations: {}, falseInfoToMAS: {}, directorOrPartnerOfCensuredBusiness: {},  directorOrPartnerOfSuspendedBusiness: {},
        directorOrPartnerOfInsolventBusiness: {}, dismissedOrResigned: {}, employerDisciplinaryProceedings: {}, disqualifiedAsDirector: {}
      },
      financialSoundness : {
        unableToFulfillFinancialObligations: {}, enteredDebtArrangement: {},
        unsatisfiedJudgmentDebt: '', bankruptcyPetitionSubject: '', undischargedBankrupt: ''
      },
      declaration: {
        employeeCode: '', designation: '', declarationName: '', declarationDate: '',  declarationSignature: ''
      },
        // AnnexA = { response: 'yes/no', regulatorName: '', incidentNature: '', incidentDetails: '', incidentDate: '',
        //            incidentStatus: '', penaltyAmount: '', remedialMeasures: '', remedialProgress: '', reasons: ''   }
  }) 

 const handleInputChange = () => {
  return
 }
 
  const handleNestedInputChange = (e, nestedName) => {
    const { name, value } = e.target; 

    // const newFormData = {  
    //   ...formData,         
    //   [name] : {
    //     ...formData[name],
    //     [nestedName] : value
    //   }  
    // }
    // setFormData(newFormData) 
    // console.log(newFormData)
  }

const qnNumber = [  '1a', '1b', '1c', '1d', '1e', '1f', '1g', '1h', '1i', '1j', '1k', '1l', '1m', '1n', '1o', '1p', '1q', '1r', '1s', '1t', '2a', '2b', '2c', '2d', '2e', '2f', '2g' ];
let qnIndex = 0

  return (
    <div>
      <Card> Fit and Proper Declaration </Card>
      <Card>
       <p className='p-2'> Subtitle: Honesty, Integrity and Reputation </p> <hr/>
       <QuestionCard inputName={"refusedRightForBusiness"} AnnexA={false}  value={'formData.refusedRightForBusiness'} handleInputChange={handleInputChange} questionNumber={qnNumber[qnIndex++]} question={"Have you been refused the right or restricted in its or his right to carry on any trade, business or profession for which a specific license, registration or other authorization is required by law in any jurisdiction ?"}  />  
       <QuestionCard inputName={"prohibitionOrderMAS"} AnnexA={false}  value={'formData.prohibitionOrderMAS'} handleInputChange={handleInputChange} questionNumber={qnNumber[qnIndex++]} question={"Have you been issued a prohibition order under any Act administered by MAS or has been prohibited from operating in any jurisdiction by any financial services regulatory authority ?"}  />  
       <QuestionCard inputName={"censuredMAS"} AnnexA={false}  value={'formData.censuredMAS'} handleInputChange={handleInputChange} questionNumber={qnNumber[qnIndex++]} question={"Have you been censured, disciplined, suspended or refused membership or registration by MAS, any other regulatory authority, an operator of a market, trade repository or clearing facility, any professional body or government agency, whether in Singapore or elsewhere ?"}  />  
       <QuestionCard inputName={"complaintSubjectMAS"} AnnexA={false}  value={'formData.complaintSubjectMAS'} handleInputChange={handleInputChange} questionNumber={qnNumber[qnIndex++]} question={"Have you been the subject of any complaint made reasonably and in good faith, relating to activities that are regulated by MAS or under any law in any jurisdiction ?"}  />  
       <QuestionCard inputName={"disciplinaryCriminalProceedings"} AnnexA={true}  value={'formData.disciplinaryCriminalProceedings'} handleInputChange={handleNestedInputChange} questionNumber={qnNumber[qnIndex++]} question={"Have you been the subject of any proceedings of a disciplinary or criminal nature or has been notified of any potential proceedings or of any investigation which might lead to those proceedings, under any law in any jurisdiction ?"}  />  

       <QuestionCard inputName={"convictedOffence"} AnnexA={true}  value={'formData.convictedOffence'} handleInputChange={handleNestedInputChange} questionNumber={qnNumber[qnIndex++]} question={"Have you been convicted of any offence, or is being subject to any pending proceedings which may lead to such a conviction, under any law in any jurisdiction?"}  />  
       <QuestionCard inputName={"judgmentCivilProceedings"} AnnexA={true}  value={'formData.judgmentCivilProceedings'} handleInputChange={handleNestedInputChange} questionNumber={qnNumber[qnIndex++]} question={"Have you had any judgment (in particular, that associated with a finding of fraud, misrepresentation or dishonesty) entered against the relevant person in any civil proceedings or is a party to any pending proceedings which may lead to such a judgment, under any law in any jurisdiction?"}  />  
       <QuestionCard inputName={"civilLiabilityFraud"} AnnexA={true}  value={'formData.civilLiabilityFraud'} handleInputChange={handleNestedInputChange} questionNumber={qnNumber[qnIndex++]} question={"Have you accepted civil liability for fraud or misrepresentation under any law in any jurisdiction ?"}  />  
       <QuestionCard inputName={"civilPenaltyEnforcement"} AnnexA={true}  value={'formData.civilPenaltyEnforcement'} handleInputChange={handleNestedInputChange} questionNumber={qnNumber[qnIndex++]} question={"Have you had any civil penalty enforcement action taken against it or him by MAS or any other regulatory authority under any law in any jurisdiction ?"}  />  
       <QuestionCard inputName={"contravenedLaws"} AnnexA={true}  value={'formData.contravenedLaws'} handleInputChange={handleNestedInputChange} questionNumber={qnNumber[qnIndex++]} question={"Have you contravened or abetted another person in breach of any laws or regulations, business rules or codes of conduct, whether in Singapore or elsewhere ?"}  />  

       <QuestionCard inputName={"investigationsSubject"} AnnexA={true}  value={'formData.investigationsSubject'} handleInputChange={handleNestedInputChange} questionNumber={qnNumber[qnIndex++]} question={"Have you been the subject of any investigations or disciplinary proceedings or been issued a warning or reprimand by MAS, any other regulatory authority, an operator of a market, trade repository or clearing facility, any professional body or government agency, whether in Singapore or elsewhere ?"}  />  
       <QuestionCard inputName={"refusedFidelityBond"} AnnexA={true}  value={'formData.refusedFidelityBond'} handleInputChange={handleNestedInputChange} questionNumber={qnNumber[qnIndex++]} question={"Have you been refused a fidelity or surety bond, whether in Singapore or elsewhere ?"}  />  
       <QuestionCard inputName={"unwillingnessComplyRegulations"} AnnexA={true}  value={'formData.unwillingnessComplyRegulations'} handleInputChange={handleNestedInputChange} questionNumber={qnNumber[qnIndex++]} question={"Have you demonstrated an unwillingness to comply with any regulatory requirement or to uphold any professional and ethical standards, whether in Singapore or elsewhere?"}  />  
       <QuestionCard inputName={"falseInfoToMAS"} AnnexA={true}  value={'formData.falseInfoToMAS'} handleInputChange={handleNestedInputChange} questionNumber={qnNumber[qnIndex++]} question={"Have you been untruthful or provided false or misleading information to MAS or been uncooperative in any dealings with MAS or any other regulatory authority in any jurisdiction ?"}  />  
       <QuestionCard inputName={"directorOrPartnerOfCensuredBusiness"} AnnexA={true}  value={'formData.directorOrPartnerOfCensuredBusiness'} handleInputChange={handleNestedInputChange} questionNumber={qnNumber[qnIndex++]} question={"Have you been a director, partner, substantial shareholder or concerned in the management of a business that has been censured, disciplined, prosecuted or convicted of a criminal offence, or been the subject of any disciplinary or criminal investigation or proceeding, in Singapore or elsewhere, in relation to any matter that took place while the person was a director, partner, substantial shareholder or concerned in the management of the business ?"}  />  

       <QuestionCard inputName={"directorOrPartnerOfSuspendedBusiness"} AnnexA={true}  value={'formData.directorOrPartnerOfSuspendedBusiness'} handleInputChange={handleNestedInputChange} questionNumber={qnNumber[qnIndex++]} question={"Have you been a director, partner, substantial shareholder or concerned in the management of a business that has been suspended or refused membership or registration by MAS, any other regulatory authority, an operator of a market, trade repository or clearing facility, any professional body or government agency, whether in Singapore or elsewhere ?"}  />  
       <QuestionCard inputName={"directorOrPartnerOfInsolventBusiness"} AnnexA={true}  value={'formData.directorOrPartnerOfInsolventBusiness'} handleInputChange={handleNestedInputChange} questionNumber={qnNumber[qnIndex++]} question={"Have you been a director, partner, substantial shareholder or concerned in the management of a business that has gone into insolvency, liquidation or administration during the period when, or within a period of one year after, the relevant person was a director, partner, substantial shareholder or concerned in the management of the business, whether in Singapore or elsewhere?"}  />  
       <QuestionCard inputName={"dismissedOrResigned"} AnnexA={true}  value={'formData.dismissedOrResigned'} handleInputChange={handleNestedInputChange} questionNumber={qnNumber[qnIndex++]} question={"Have you been dismissed or asked to resign from (A) office; (B) employment; (C) a position of trust; or; (D) a fiduciary appointment or similar position, whether in Singapore or elsewhere ?"}  />  
       <QuestionCard inputName={"employerDisciplinaryProceedings"} AnnexA={true}  value={'formData.employerDisciplinaryProceedings'} handleInputChange={handleNestedInputChange} questionNumber={qnNumber[qnIndex++]} question={"Have you been subject to disciplinary proceedings by his current or former employer(s), whether in Singapore or elsewhere ?"}  />  
       <QuestionCard inputName={"disqualifiedAsDirector"} AnnexA={true}  value={'formData.disqualifiedAsDirector'} handleInputChange={handleNestedInputChange} questionNumber={qnNumber[qnIndex++]} question={"Have you been disqualified from acting as a director or disqualified from acting in any managerial capacity, whether in Singapore or elsewhere ?"}  />  
      </Card>
      
      <Card>
        <p className='p-2'> Subtitle: Financial Soundness </p> <hr/>
       <QuestionCard inputName={"unableToFulfillFinancialObligations"} AnnexA={false}  value={'formData.unableToFulfillFinancialObligations'} handleInputChange={handleNestedInputChange} questionNumber={qnNumber[qnIndex++]} question={"Have you been unable to fulfill any of his/her financial obligations, whether in Singapore or elsewhere?"}  />  
       <QuestionCard inputName={"enteredDebtArrangement"} AnnexA={true}  value={'formData.enteredDebtArrangement'} handleInputChange={handleNestedInputChange} questionNumber={qnNumber[qnIndex++]} question={"Have you entered into a compromise or scheme of arrangement (including a debt repayment scheme) with its or his/her creditors, or made an assignment for the benefit of its or his/her creditors, being a compromise, scheme of arrangement or assignment that is still in operation, whether in Singapore or elsewhere?"}  />  
       <QuestionCard inputName={"unsatisfiedJudgmentDebt"} AnnexA={true}  value={'formData.unsatisfiedJudgmentDebt'} handleInputChange={handleInputChange} questionNumber={qnNumber[qnIndex++]} question={"Have you been subject to a judgment debt which is unsatisfied, either in whole or in part, whether in Singapore or elsewhere ?"}  />  
       <QuestionCard inputName={"bankruptcyPetitionSubject"} AnnexA={false}  value={'formData.bankruptcyPetitionSubject'} handleInputChange={handleInputChange} questionNumber={qnNumber[qnIndex++]} question={"Have you been the subject of a bankruptcy petition, whether in Singapore or elsewhere?"}  />  
       <QuestionCard inputName={"undischargedBankrupt"} AnnexA={false}  value={'formData.undischargedBankrupt'} handleInputChange={handleInputChange} questionNumber={qnNumber[qnIndex++]} question={"Have you been adjudicated a bankrupt and the bankruptcy is undischarged, whether in Singapore or elsewhere ?"}  />  
 
        <p> ask if below 2 these make sense, seems repeated. </p>
       <QuestionCard inputName={""} AnnexA={false}  value={'formData.unusualOwnershipStructure'} handleInputChange={handleInputChange} questionNumber={qnNumber[qnIndex++]} question={"Question: 2d. Are you, or have you been subject to a judgment debt which is unsatisfied, either in whole or in part, whether in Singapore or elsewhere ?"}  />  
       <QuestionCard inputName={""} AnnexA={false}  value={'formData.unusualOwnershipStructure'} handleInputChange={handleInputChange} questionNumber={qnNumber[qnIndex++]} question={"Question: 2fg Have you been subject to any other process outside Singapore that is similar to those referred to in ?"}  />  
      </Card>

      <Card>
        <DeclarationCard />
      </Card>

        <br/> <br/>  
    </div>
  )
}

export default _FnPForm