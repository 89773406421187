import React, { useState, useEffect } from 'react';
import RiskRatingCounterChart from './RiskRatingCounterChart';
import StatisticsMonitoring from './StatisticsMonitoring';
import Notifications from './Notifications';
import { RightContainer, Card, Row } from "../../shared/utils"

const _FnPDashboard = () => {

  return (
    <div> {/* <div className="container p-4"> */}
        <Card>
          <h2 className="text-lg font-semibold"> Fit & Proper Dashboard </h2>
        </Card>
 
        <h2 className="text-lg font-semibold text-gray-600 mx-2 p-3 bg-gray-200">Notifications</h2>
        <Card className="overflow-auto h-80 ">
            <Notifications />
        </Card>

        <Row>   
          <Card className='w-3/5 h-fit'> 
            <h3 className="text-lg font-semibold mb-2">Completed Forms</h3>
            <RiskRatingCounterChart />
          </Card>
          <StatisticsMonitoring />
        </Row>
    </div>
  );
};

export default _FnPDashboard