import React, { useState, cloneElement } from 'react';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { jwtDecode } from 'jwt-decode';
import { toast } from 'react-toastify';

const ModalComponent = ({children, title, ModalTitle,  CustomButton }) => {
    const [open, setOpen] = useState(false);
    const token = localStorage.getItem('accessToken')
    let decodedToken
    let userRole

    if (token) {
        decodedToken = jwtDecode(token) 
        userRole = decodedToken.role
    }

    const handleOpenModal = () => { 
        if (userRole == 'viewer') { 
            toast.info("Viewer cannot perform that action") 
            return
        }
        setOpen(true)
    }

    const handleCloseModal = () => {
        setOpen(false);
    };
    
    return (
        <div>
            {
                CustomButton? 
                ( <span className='cursor-pointer' onClick={handleOpenModal}> 
                        <CustomButton  />    
                </span>)
                : 
                (<span onClick={handleOpenModal} className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg cursor-pointer">
                    {title}
                </span>) 
            }
            {/*  custom button can be placead as a ternary operator between <button> itself </button> */}


            <Dialog open={open} onClose={() => setOpen(false)}>
                <div className='flex justify-between py-3 px-4'>
                    <h2 className="text-lg font-semibold mt-1"> {ModalTitle || title} </h2>
                    <Button onClick={() => setOpen(false)}> X </Button>
                </div>
                <hr/>
                {/* {children} */}
                {React.cloneElement(children, { handleCloseModal: handleCloseModal })}  {/* To supposedly pass a prop within this to the children within it */}
            </Dialog>
        </div>
    );
};

export default ModalComponent;      